export const AppAction = {
  BLOCK_TASK: 'BLOCK_TASK',
  UNBLOCK_TASK: 'UNBLOCK_TASK',
  CACHE_REQUEST: 'CACHE_REQUEST',
  CACHE_REQUESTS: 'CACHE_REQUESTS',
  CLEAR_CACHED_REQUEST: 'CLEAR_CACHED_REQUEST',
  CACHE_REQUEST_HIT: 'CACHE_REQUEST_HIT',
};

export const ModalAction = {
  SHOW_MODAL: 'SHOW_MODAL',
};

export const UserAction = {
  LOG_IN_WITH_PASSWORD: 'LOG_IN_WITH_PASSWORD',
  GET_USER_INFO: 'GET_USER_INFO',
  LOG_OUT: 'LOG_OUT',
  GET_VISIBLE_ADS: 'GET_VISIBLE_ADS',
  GET_CLASSES: 'GET_CLASSES',
};

export const ProjectAction = {
  GET_PROJECTS: 'GET_PROJECTS',
  GET_PROJECT_REQUESTS: 'GET_PROJECT_REQUESTS',
  GET_PROJECT_TOPICS_SCHOOL: 'GET_PROJECT_TOPICS_SCHOOL',
  GET_PROJECT_TOPICS_COMPANY: 'GET_PROJECT_TOPICS_COMPANY',
  GET_PROJECT_PLAGIARISM_REQUESTS: 'GET_PROJECT_PLAGIARISM_REQUESTS',
  CREATE_PROJECT_PLAGIARISM_REQUESTS: 'CREATE_PROJECT_PLAGIARISM_REQUESTS',
};

export const SemesterAction = {
  GET_SEMESTERS: 'GET_SEMESTERS',
};

export const CompanyAction = {
  GET_COMPANY: 'GET_COMPANY',
  GET_COMPANIES: 'GET_COMPANIES',
  GET_COMPANY_FEEDBACK: 'GET_COMPANY_FEEDBACK',
  GET_COMPANY_ADS: 'GET_COMPANY_ADS',
};

export const FileAction = {
  UPLOAD_FILE: 'UPLOAD_FILE',
};

export const FormAction = {
  GET_FORMS: 'GET_FORMS',
  CREATE_FORM: 'CREATE_FORM',
};
