import { AppAction } from 'constants/actions';

export const withBlock = (action) => ({
  ...action,
  shouldBlock: true,
});

export const withCache = (action, tag) => (dispatch, getState) => {
  const state = getState();
  let cacheKey = action.type;
  if (tag) {
    cacheKey = `${cacheKey}-${tag}`;
  }
  const cacheExpiration = state.app.cachedRequests[cacheKey];
  if (cacheExpiration && cacheExpiration > Date.now()) {
    return dispatch({
      type: AppAction.CACHE_REQUEST_HIT,
      payload: {
        cacheKey,
        cacheExpiration,
      },
    });
  }
  return dispatch({
    ...action,
    shouldCache: true,
    cacheMetadata: {
      cacheKey,
    },
  });
};

export const withIgnoreError = (action) => ({
  ...action,
  ignoreError: true,
});
