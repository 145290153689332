export const validateReduxActionKeys = (allActionKeysObjects) => {
  // Find non-unique action keys
  const actionKeys = Object.keys(allActionKeysObjects)
    .map(key => allActionKeysObjects[key])
    .reduce((resultArray, actionKeysObject) => [
      ...resultArray,
      ...Object.keys(actionKeysObject).map(key => actionKeysObject[key]),
    ], [])
    .slice()
    .sort();
  const duplicatedKeys = [];
  for (let i = 0; i < actionKeys.length - 1; i++) {
    if (i !== 0) {
      if (actionKeys[i - 1] === actionKeys[i]) {
        duplicatedKeys.push(actionKeys[i]);
      }
    }
  }
  if (duplicatedKeys.length === 0) return;
  throw new Error(`Found non-unique redux action key(s): ${duplicatedKeys.join(', ')}`);
};
