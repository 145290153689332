import React from 'react';
import { toast, Icon } from '@ahaui/react';

const showMessage = (type, title, content, options = {}) => {
  const render = (iconName, title, content) => (
    <div className="u-flex u-flexGrow-1">
      <div className="u-marginRightExtraSmall">
        <Icon name={iconName} size="medium" />
      </div>
      <div className="u-flexGrow-1">
        <div className="u-fontMedium u-marginBottomExtraSmall">{title}</div>
        <div className="u-textWordBreak">{content}</div>
      </div>
    </div>
  );
  switch (type) {
    case 'error':
      toast.error(() => render('alert', title, content), {
        autoClose: 6000,
        ...options,
      });
      break;
    case 'warning':
      toast.warn(() => render('warning', title, content), {
        autoClose: 6000,
        ...options,
      });
      break;
    case 'info':
      toast.info(() => render('informationCircle', title, content), {
        autoClose: 10000,
        ...options,
      });
      break;
    case 'success':
      toast.success(() => render('checkmarkCircle', title, content), {
        autoClose: 3000,
        ...options,
      });
      break;
    default:
      break;
  }
};

/**
 *
 * @param {string|object} error Error
 * @returns {string|array} The error message or a list of error object with structure: { name, message }
 */
const getErrorMsg = (error) => {
  let message = '';

  if (
    typeof error === 'object'
    && !Array.isArray(error)
  ) {
    if (error.data?.errorMessage) {
      message = error.data.errorMessage;
    } else {
      message = 'Có lỗi xảy ra.';
    }
  } else {
    message = error || 'Có lỗi xảy ra.';
  }
  return message;
};

const error = (...args) => {
  if (args.length === 1) {
    showMessage('error', 'Lỗi', getErrorMsg(args[0]));
  } else if (args.length === 2) {
    showMessage('error', args[1], getErrorMsg(args[0]));
  } else {
    showMessage('error', 'Lỗi', 'Có lỗi xảy ra.');
  }
};

const success = (content, title = 'Thành công', options) => showMessage('success', title, content, options);

const info = (content, title = 'Thông báo', options) => showMessage('info', title, content, options);

const warning = (content, title = 'Cảnh báo', options) => showMessage('warning', title, content, options);

export default {
  error,
  success,
  info,
  warning,
  getErrorMsg,
};
