import React, { useMemo, useCallback } from 'react';
import { Form, TagInput } from '@ahaui/react';
import formatter from 'utils/formatter';

export default function FormTagInputGroup({
  id,
  register,
  watch,
  setValue,
  errors,
  label,
  name,
  required,
  registerProps,
  formGroupProps,
  formLabelProps,
  formInputWrapperProps,
  feedback,
  renderFeedback,
  onChange,
  tagValidator,
  tagFormatter,
  ...formInputProps
}) {
  const defaultValue = useMemo(() => [], []);
  const value = watch(name) ?? defaultValue;

  const chosenTagFormatter = tagFormatter || formatter.defaultTagsFormatter;

  const { ref } = register?.(name, {
    required,
    ...registerProps,
  });

  const onInputChange = useCallback((tags) => {
    const formattedTags = chosenTagFormatter(tags);

    const validatedTags = tagValidator
      ? formattedTags.filter(tag => tagValidator(tag))
      : formattedTags;

    if (onChange) {
      onChange(validatedTags);
    }
    if (setValue) {
      setValue(name, validatedTags);
    }
  }, [setValue, onChange, name, tagValidator, chosenTagFormatter]);

  return (
    <Form.Group
      controlId={id}
      requiredControl={required}
      {...formGroupProps}
    >
      {label && (
        <Form.Label
          {...formLabelProps}
        >
          {label}
        </Form.Label>
      )}
      <div
        className="u-flexGrow1"
        {...formInputWrapperProps}
      >
        <TagInput
          ref={ref}
          value={value}
          onChange={onInputChange}
          {...formInputProps}
        />
        {errors?.[name] && (
        <Form.Feedback type="invalid">
          {errors[name].message}
        </Form.Feedback>
        )}
        {renderFeedback && feedback && renderFeedback(feedback)}
      </div>
    </Form.Group>
  );
}
