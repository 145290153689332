import React from 'react';
import { Skeleton } from '@ahaui/react';
import ReactImageFallback from 'react-image-fallback';
import fallback from 'assets/images/misc/fallback.png';

export default function Image({
  isSkeleton = false,
  skeletonHeight = 150,
  src,
  ...rest
}) {
  const skeleton = <Skeleton height={skeletonHeight} />;
  return (
    <ReactImageFallback
      src={isSkeleton ? skeleton : src}
      fallbackImage={fallback}
      initialImage={skeleton}
      alt=""
      {...rest}
    />
  );
}
