import React from 'react';
import { Logo, SafeAnchor } from '@ahaui/react';

export default function Footer() {
  return (
    <footer className="u-positionRelative u-backgroundPrimaryDarker u-textWhite u-paddingTopMedium u-paddingBottomExtraSmall">
      <div className="Container">
        <div className="Grid">
          <div className="u-sizeFull xl:u-size8of12 u-marginBottomSmall">
            <div className="u-flex">
              <div className="u-lineHeightNone u-marginRightSmall u-flexShrink0">
                <Logo className="u-border" name="hustPng" height={80} />
              </div>
              <div>
                <div>HỆ THỐNG DO TRƯỜNG ĐẠI HỌC BÁCH KHOA HÀ NỘI THIẾT KẾ & PHÁT TRIỂN.</div>
                <div>
                  <span>Để hỗ trợ vui lòng gửi mail đến </span>
                  <SafeAnchor
                    href="mailto:hung.nguyenthanh2@hust.edu.vn"
                    target="_blank"
                    className="u-textWhite u-fontMedium"
                  >
                    hung.nguyenthanh2@hust.edu.vn
                  </SafeAnchor>
                </div>
                <div>09.77.86.33.11</div>
              </div>
            </div>
          </div>
          <div className="u-sizeFull xl:u-size4of12 u-marginBottomSmall">
            Số 1 Đại Cồ Việt, Phường Bách Khoa, Quận Hai Bà Trưng, Thành phố Hà Nội
          </div>
        </div>
      </div>
    </footer>
  );
}
