import React from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { KeyboardKey } from 'constants/common';
import { ErrorMessage } from 'constants/message';
import { resetPassword } from 'store/actions/user.action';
import FormGroup from 'components/Common/Form/FormGroup';

import ModalBase from './ModalBase';

export default function ForgotPasswordModal({
  onModalClose,
  prefilledUsername,
}) {
  const dispatch = useDispatch();
  const formHookInfo = useForm({
    criteriaMode: 'all',
    defaultValues: {
      username: prefilledUsername || '',
    },
  });

  const {
    handleSubmit,
    formState: {
      isSubmitting,
      errors,
    },
  } = formHookInfo;

  const onSubmit = handleSubmit(async (data) => {
    const { success } = await dispatch(resetPassword({
      username: data.username,
    }));
    if (success) {
      onModalClose();
    }
  });

  const onKeyDown = (e) => e.key === KeyboardKey.ENTER && onSubmit();

  return (
    <ModalBase
      headerText="Đặt lại mật khẩu"
      size="medium"
      closable={!isSubmitting}
      onHide={onModalClose}
      onKeyDown={onKeyDown}
      body={(
        <>
          <p>Vui lòng nhập Tên đăng nhập (MSSV) hoặc Email vào ô dưới đây để tiến hành đặt lại mật khẩu.</p>
          <FormGroup
            id="bkeu-reset-pwd-username"
            formHookInfo={formHookInfo}
            label="Tên đăng nhập hoặc Email"
            name="username"
            type="text"
            required={ErrorMessage.REQUIRED_FIELD}
            disabled={isSubmitting}
            placeholder="Nhập MSSV hoặc Email"
            maxLength={100}
          />
        </>
      )}
      footerType="single"
      primaryButtonText="THỰC HIỆN"
      onClickPrimaryButton={onSubmit}
      disablePrimaryButton={isSubmitting || (Object.keys(errors).length) > 0}
    />
  );
}
