import React from 'react';
import moment from 'moment';

export const renderDateTime = ({
  key,
  rowData,
  columnConfig,
}) => {
  const timestamp = rowData[columnConfig.name];

  let displayTime;

  if (timestamp === -1) {
    displayTime = 'N/A';
  } else {
    displayTime = moment(timestamp).format('DD/MM HH:mm');
  }

  return (
    <td key={key}>
      {displayTime}
    </td>
  );
};

export const renderDate = ({
  key,
  rowData,
  columnConfig,
}) => {
  const timestamp = rowData[columnConfig.name];

  let displayTime;

  if (timestamp === -1) {
    displayTime = 'N/A';
  } else {
    displayTime = moment(timestamp).format('DD-MM-YYYY');
  }

  return (
    <td key={key}>
      {displayTime}
    </td>
  );
};
