export const isEmptyObject = obj => Object.keys(obj).length === 0 && obj.constructor === Object;

export const isNotEmptyArray = (array) => {
  if (!Array.isArray(array)) {
    return false;
  }
  return array.length > 0;
};

export const findField = (object, fieldPath) => {
  if (typeof object !== 'object') return undefined;
  if (!fieldPath) return undefined;

  const fieldPathParts = fieldPath.split('.');
  let currentObject = object;
  let result;
  while (fieldPathParts.length !== 0) {
    if (typeof object !== 'object') {
      break;
    }
    const currentPart = fieldPathParts.shift();
    if (fieldPathParts.length === 0) {
      result = currentObject[currentPart];
      break;
    } else {
      currentObject = currentObject[currentPart];
    }
  }

  return result;
};

export const convertObjToArray = (obj, keyToId = true) => {
  if (typeof obj !== 'object') {
    return [];
  }
  return keyToId
    ? Object.keys(obj).map(key => ({
      ...obj[key],
      id: key,
    }))
    : Object.keys(obj).map(key => ({
      ...obj[key],
    }));
};

export const commaSeparatedToArray = (commaSeparated) => {
  if (!commaSeparated) return [];
  return commaSeparated.split(',').map(item => item.trim()).filter(item => item !== '');
};

export const arrayToCommaSeparated = (array, withSpace = false) => {
  if (!array || array.length === 0) return '';
  return array.map(item => item.trim()).join(withSpace ? ', ' : ',');
};

export const commaSeparatedToArrayRemoveDuplicates = (commaSeparated) => {
  if (!commaSeparated) return [];
  return commaSeparatedToArray(commaSeparated).map(value => value && value.toLowerCase()).filter((value, pos, self) => self.indexOf(value) === pos);
};
