import React, { useState } from 'react';
import { SafeAnchor } from '@ahaui/react';

export default function ToggleShowWrapper({
  children,
}) {
  const [show, setShow] = useState(false);

  return (
    <>
      {show && children}
      <SafeAnchor
        onClick={() => setShow(show => !show)}
      >
        {show ? 'Ẩn chi tiết' : 'Chi tiết'}
      </SafeAnchor>
    </>
  );
}
