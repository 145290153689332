import React from 'react';
import { SafeAnchor } from '@ahaui/react';
import useFilter from 'hooks/useFilter';
import useSemesters from 'hooks/useSemesters';
import Table from 'components/Common/Table';
import Filter from 'components/Common/Filter';

const projectRefs = [
  {
    id: 1,
    projectTitle: 'Ứng dụng AI vào F&B',
    studentName: 'Đào Duy Nam',
    studentYear: '61',
    tempType: 'KSCQ',
    tempEdu: 'CNPM',
    teacherName: 'Nguyễn Văn A',
    thesisUrl: 'N/A',
  },
  {
    id: 2,
    projectTitle: 'Ứng dụng quản lý bán hàng',
    studentName: 'Đào Văn B',
    studentYear: '60',
    tempType: 'KSCQ',
    tempEdu: 'HTTT',
    teacherName: 'Nguyễn Văn C',
    thesisUrl: 'N/A',
  },
  {
    id: 3,
    projectTitle: 'Ứng dụng mạng IoT',
    studentName: 'Lê Văn D',
    studentYear: '59',
    tempType: 'KSCQ',
    tempEdu: 'CNPM',
    teacherName: 'Nguyễn Văn A',
    thesisUrl: 'File',
  },
];

export default function ProjectReferences() {
  const {
    semester,
    setSemester,
    allSemesters,
  } = useSemesters();

  const {
    searchPattern,
    onSearchPatternChange,
    filteredItems: filteredProjectRefs,
  } = useFilter({
    items: projectRefs,
    options: {
      keys: ['title'],
      distance: 500,
    },
  });

  const projectTitleRenderer = ({ key, rowData }) => (
    <td key={key}>
      <SafeAnchor>
        {rowData.projectTitle}
      </SafeAnchor>
    </td>
  );

  return (
    <div className="u-paddingVerticalLarge u-paddingHorizontalSmall">
      <div className="u-backgroundWhite u-border u-roundedLarge u-shadowLarge u-paddingSmall">
        <div className="u-text500 u-fontMedium u-marginBottomSmall">Đồ án tham khảo</div>
        <div>
          <Filter.Container>
            <Filter.SelectBox
              name="select-semester"
              label="Kỳ học"
              value={semester}
              options={allSemesters}
              onChange={setSemester}
            />
            <Filter.SearchString
              name="search-by-title"
              value={searchPattern}
              onChange={onSearchPatternChange}
            />
          </Filter.Container>
          <Table
            columns={[
              {
                name: 'projectTitle',
                header: 'Tên đề tài',
                cellRenderer: projectTitleRenderer,
              },
              {
                name: 'studentName',
                header: 'Tên Sinh viên',
              },
              {
                name: 'studentYear',
                header: 'Khóa',
              },
              {
                name: 'tempType',
                header: 'Hệ',
              },
              {
                name: 'tempEdu',
                header: 'Ngành',
              },
              {
                name: 'teacherName',
                header: 'GV Hướng dẫn',
              },
              {
                name: 'thesisUrl',
                header: 'File báo cáo',
              },
            ]}
            rows={filteredProjectRefs}
          />
        </div>
      </div>
    </div>
  );
}
