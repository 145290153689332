import { combineReducers } from 'redux';
import app from './app.reducer';
import user from './user.reducer';
import modal from './modal.reducer';
import project from './project.reducer';
import semester from './semester.reducer';
import company from './company.reducer';
import form from './form.reducer';

export default combineReducers({
  app,
  user,
  modal,
  project,
  semester,
  company,
  form,
});
