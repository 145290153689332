import { UserAction } from 'constants/actions';
import Auth from 'utils/auth';
import { getSuccessAction, getFailureAction } from 'utils/redux';

const getInitialState = () => ({
  isLoggedIn: Auth.isAuth(),
  info: null,
  ads: null,
  classes: null,
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case getSuccessAction(UserAction.LOG_IN_WITH_PASSWORD): {
      return {
        ...state,
        isLoggedIn: Auth.isAuth(),
      };
    }

    case getSuccessAction(UserAction.GET_USER_INFO): {
      return {
        ...state,
        info: action.payload ? { ...action.payload } : null,
      };
    }

    case getSuccessAction(UserAction.LOG_OUT):
    case getFailureAction(UserAction.LOG_OUT): {
      return getInitialState();
    }

    case getSuccessAction(UserAction.GET_VISIBLE_ADS): {
      return {
        ...state,
        ads: [...action.payload],
      };
    }

    case getSuccessAction(UserAction.GET_CLASSES): {
      return {
        ...state,
        classes: {
          ...state.classes,
          [action.extraPayload.semester]: [...action.payload],
        },
      };
    }

    default:
      break;
  }
  return state;
};
