import React from 'react';
import { Message, SafeAnchor } from '@ahaui/react';
import ExternalLink from 'components/Common/ExternalLink';

export default function FAQ() {
  return (
    <div className="u-paddingVerticalLarge u-paddingHorizontalSmall">
      <div className="u-backgroundWhite u-border u-roundedLarge u-shadowLarge u-paddingSmall">
        <div className="u-text500 u-fontMedium u-marginBottomSmall">Hướng dẫn sử dụng & Hỏi đáp</div>
        <div className="u-marginVerticalExtraSmall">
          <div className="u-fontMedium u-textPrimary">A. Quy trình đăng ký Đồ án/Thực tập</div>
          <ol className="u-text200 u-paddingLeftNone u-listStylePositionInside">
            <li className="u-marginBottomSmall">
              GV/DN cập nhật danh sách Đề tài trên hệ thống
              {' '}
              <ExternalLink href="https://qldt.hust.edu.vn">https://qldt.hust.edu.vn</ExternalLink>
            </li>
            <li className="u-marginBottomSmall">
              SV đăng nhập hệ thống (
              <SafeAnchor>{window.location.origin}</SafeAnchor>
              ) để tham khảo các đề tài, đăng ký nguyện vọng (Thường là 3 nguyện vọng) đồng thời cung cấp các thông tin cần thiết (CV, điểm tiếng Anh, điểm CPA, ...)
            </li>
            <li className="u-marginBottomSmall">
              GV/DN duyệt nguyện vọng, có thể yêu cầu gặp/phỏng vấn, và xác nhận về nguyện vọng
            </li>
            <li className="u-marginBottomSmall">
              Nếu GV/DN đồng ý với nguyên vọng, việc đăng ký hoàn thành, SV sẽ tự liên hệ với GV/DN cho các bước tiếp theo
            </li>
            <li className="u-marginBottomSmall">
              Nếu GV/DN từ chối nguyện vọng, SV sẽ được chuyển xuống nguyện vọng tiếp theo
            </li>
            <li className="u-marginBottomSmall">
              Nếu hết các nguyện vọng mà SV không được nhận, Viện sẽ tự phân công SV cho một GV/DN có thể tiếp nhận
            </li>
            <li className="u-marginBottomSmall">
              Kết thúc Đồ án/Thực tập, SV sẽ nộp báo cáo và kết quả online đồng thời đánh giá, phản hồi về GV/DN
            </li>
            <li className="u-marginBottomSmall">
              GV/DN đánh giá, phản hồi đối với SV trên hệ thống
            </li>
          </ol>
          <div className="u-marginBottomSmall">
            <Message variant="warning">
              <Message.Container>
                <Message.Title>
                  Lưu ý
                </Message.Title>
                <Message.Content className="u-text200">
                  <ul className="u-paddingLeftNone u-marginBottomNone u-listStylePositionInside">
                    <li className="u-marginBottomSmall">
                      Việc đăng ký trên hệ thống QLĐT chỉ nhằm mục đích hỗ trợ SV theo nguyện vọng,
                      {' '}
                      <strong>KHÔNG</strong>
                      {' '}
                      thay thế cho việc đăng ký trên hệ thống SIS.
                    </li>
                    <li>Trường hợp SV không nhìn thấy DN mình mong muốn thực tập trên hệ thống, SV chủ động thông báo doanh nghiệp liên hệ với Bộ phận phụ trách thực tập doanh nghiệp để kiểm tra điều kiện nhận sinh viên thực tập và tuân thủ qui trình của nhà trường, thống nhất phương pháp giám sát và đánh giá sinh viên, đảm bảo quyền lợi sinh viên khi thực tập doanh nghiệp cũng như đảm bảo qui tắc đánh giá kết quả thực tập của sinh viên. Viện không gửi sinh viên đi thực tập tại các doanh nghiệp không liên hệ với nhà trường để thực hiện thỏa mãn các điều kiện trên.</li>
                  </ul>
                </Message.Content>
              </Message.Container>
            </Message>
          </div>

          <div className="u-fontMedium u-textPrimary">B. Làm thế nào để DN có thể tiếp nhận SV thực tập?</div>
          <ol className="u-text200 u-paddingLeftNone u-listStylePositionInside">
            <li className="u-marginBottomSmall">
              Để có thể tiếp nhận SV thực tập, DN phải đăng ký tài khoản và điền đầy đủ thông tin trên hệ thống quản lý của Viện (
              <ExternalLink href="https://cam.soict.ai">https://cam.soict.ai</ExternalLink>
              ) để Viện có thể đánh giá về khả năng tiếp nhận của DN, bao gồm về lĩnh vực, quy mô, chương trình thực tập, đào tạo, độ phù hợp so với chương trình đào tạo, ...
            </li>
            <li className="u-marginBottomSmall">
              Trong trường hợp cần hỗ trợ, quý DN liên hệ trực tiếp với Bộ phận hợp tác Doanh nghiệp của Viện:
              <ul className="u-paddingLeftSmall u-listStylePositionInside">
                <li className="u-marginVerticalExtraSmall">
                  <strong>Phạm Ánh Tuyết</strong>
                  : tuyetpta@soict.hust.edu.vn - 0982105519
                </li>
                <li>
                  <strong>Nguyễn Tuấn Hải</strong>
                  : haintu@soict.hust.edu.vn - 0983331526
                </li>
              </ul>
            </li>
          </ol>

          <div className="u-fontMedium u-textPrimary">C. Thời lượng và đối tượng của kỳ thực tập DN như thế nào?</div>
          <div className="u-marginVerticalExtraSmall u-borderLeft u-borderRight u-borderBottom u-overflowHorizontalAuto">
            <table className="Table Table--striped Table--hoverable u-backgroundWhite u-textDark u-text200">
              <thead className="u-textNoWrap">
                <tr>
                  <th scope="col">Tên đợt thực tập</th>
                  <th scope="col">Thời gian thực tập</th>
                  <th scope="col">Thời lượng thực tập tối thiểu</th>
                  <th scope="col">Đối tượng thực tập</th>
                  <th scope="col">Số lượng SV (dự kiến)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Thực tập doanh nghiệp học kỳ I</td>
                  <td>Đầu tháng 9 đến giữa tháng 12</td>
                  <td>
                    <ul className="u-paddingLeftNone u-marginNone u-listStylePositionInside">
                      <li>Hệ kỹ sư: 160 giờ bán thời gian (part-time) hoặc tương đương</li>
                      <li>Hệ cử nhân: 600 giờ toàn thời gian (full-time) hoặc tương đương</li>
                    </ul>
                  </td>
                  <td>Sinh viên năm 3, 4, 5</td>
                  <td>250 - 400</td>
                </tr>
                <tr>
                  <td>Thực tập doanh nghiệp học kỳ II</td>
                  <td>Giữa tháng 1 đến cuối tháng 5</td>
                  <td>160 giờ bán thời gian (part-time) hoặc tương đương</td>
                  <td>Sinh viên năm 3, 4, 5</td>
                  <td>80 - 100</td>
                </tr>
                <tr>
                  <td>Thực tập doanh nghiệp học kỳ hè</td>
                  <td>Giữa tháng 6 đến giữa tháng 8 hàng năm</td>
                  <td>
                    <ul className="u-paddingLeftNone u-marginNone u-listStylePositionInside">
                      <li>Hệ kỹ sư: 160 giờ bán thời gian (part-time) hoặc tương đương</li>
                      <li>Hệ cử nhân: 600 giờ toàn thời gian (full-time) hoặc tương đương</li>
                    </ul>
                  </td>
                  <td>Sinh viên năm 3, 4, 5</td>
                  <td>300 - 400</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
