import React from 'react';
import ExternalLink from 'components/Common/ExternalLink';
import CopyButton from 'components/Common/CopyButton';

import ModalBase from './ModalBase';

export default function ClassInfoModal({
  onModalClose,
  classInfo,
}) {
  return (
    <ModalBase
      headerText="Thông tin lớp"
      size="medium"
      closable
      onHide={onModalClose}
      body={(
        <div className="u-positionRelative">
          <div className="u-fontMedium u-textPrimary u-text400 u-marginBottomExtraSmall">{`${classInfo.courseId} - ${classInfo.displayName}`}</div>
          <div className="u-textTruncate">
            <span>Link lớp học online: </span>
            <ExternalLink
              href={classInfo.classUrl}
            >
              {classInfo.classUrl}
            </ExternalLink>
          </div>
          <div>
            <span>Mã truy cập lớp học online: </span>
            <strong>{classInfo.accessCode}</strong>
            <CopyButton
              textToCopy={classInfo.accessCode}
              className="u-marginLeftTiny"
            />
          </div>
        </div>
      )}
      footerType="single-right"
      primaryButtonText="ĐÓNG"
      onClickPrimaryButton={onModalClose}
    />
  );
}
