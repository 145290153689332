import React from 'react';
import DOMPurify from 'dompurify';
import { EmptyState } from '@ahaui/react';
import { getInternTypeName, getTopicTypeName } from 'utils/message';
import EmptyRows from 'components/Common/EmptyRows';

export default function ProjectTopicsSection({
  companyInfo,
}) {
  const {
    projectTopics,
  } = companyInfo;

  const renderProjectTopics = () => {
    const numberOfCols = 3;
    const numberOfRows = 3;

    if (!projectTopics) {
      return (
        <EmptyRows
          numberOfCols={numberOfCols}
          numberOfRows={numberOfRows}
        />
      );
    }
    if (projectTopics.length === 0) {
      return (
        <tr>
          <td colSpan={numberOfCols} className="u-backgroundWhite">
            <EmptyState name="general" className="u-widthFull">
              <EmptyState.Description>
                Không có liên hệ
              </EmptyState.Description>
            </EmptyState>
          </td>
        </tr>
      );
    }
    return projectTopics.map((topic, index) => (
      <tr key={topic.id}>
        <td style={{ width: '1%' }}>{index + 1}</td>
        <td>
          <div className="u-fontMedium u-textPrimary">{topic.title}</div>
          <div>
            <span>Loại thực tập: </span>
            <span className="u-fontMedium">{getInternTypeName(topic.internType)}</span>
          </div>
          <div>
            <span>Loại đề tài: </span>
            <span className="u-fontMedium">{getTopicTypeName(topic.topicType)}</span>
          </div>
          <div>
            <span>Số SV: </span>
            <span className="u-fontMedium">{topic.studentNum}</span>
          </div>
          <div>
            <span>Trợ cấp: </span>
            <span className="u-fontMedium">{topic.salaryOffer}</span>
          </div>
        </td>
        <td>
          <div
            className="all-imageMaxWidthFull"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(topic.description) }}
          />
        </td>
      </tr>
    ));
  };

  return (
    <div className="u-marginVerticalExtraSmall u-borderLeft u-borderRight u-borderBottom u-overflowHorizontalAuto">
      <table className="Table Table--striped Table--hoverable u-backgroundWhite u-textDark all-text200">
        <thead className="u-textNoWrap">
          <tr>
            <th scope="col">#</th>
            <th scope="col" style={{ width: '40%' }}>Tên Đề tài</th>
            <th scope="col" style={{ width: '60%' }}>Mô tả</th>
          </tr>
        </thead>
        <tbody>
          {renderProjectTopics()}
        </tbody>
      </table>
    </div>
  );
}
