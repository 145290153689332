import React, { useEffect } from 'react';
import {
  SafeAnchor, Separator, Button, Icon,
} from '@ahaui/react';
import { useDispatch, useSelector } from 'react-redux';
import { getForms } from 'store/actions/form.action';
import { withBlock, withCache } from 'store/actions/hoc.action';
import { showModal } from 'store/actions/modal.action';
import { getFormStatusName, getFormName } from 'utils/message';
import { ModalKey } from 'constants/common';
import { renderDate } from 'components/Common/Table/renderers';
import Table from 'components/Common/Table';

export default function OnlineForm() {
  const dispatch = useDispatch();
  const forms = useSelector(state => state.form.forms);

  useEffect(() => {
    dispatch(withCache(withBlock(getForms())));
  }, [dispatch]);

  const onFormTitleClick = (formType, formData) => {
    dispatch(showModal(ModalKey.FORM, {
      formData,
      formType,
    }));
  };

  const titleRenderer = ({
    key,
    rowData,
  }) => (
    <td key={key}>
      <SafeAnchor
        onClick={() => onFormTitleClick(rowData.formType, rowData.data)}
      >
        {getFormName(rowData.formType)}
      </SafeAnchor>
    </td>
  );

  const statusRenderer = ({
    key,
    rowData,
  }) => (
    <td key={key}>
      {getFormStatusName(rowData.status)}
    </td>
  );

  return (
    <div className="u-paddingVerticalLarge u-paddingHorizontalSmall">
      <div className="u-backgroundWhite u-border u-roundedLarge u-shadowLarge u-paddingSmall u-flex u-flexColumn">
        <div className="u-text500 u-fontMedium u-marginBottomSmall">Danh sách đơn đã yêu cầu</div>
        <Button
          size="small"
          className="u-alignSelfEnd"
        >
          <Button.Icon>
            <Icon name="plus" />
          </Button.Icon>
          <Button.Label>Tạo yêu cầu</Button.Label>
        </Button>
        <Table
          showPagination={false}
          rows={forms}
          columns={[
            {
              name: 'title',
              header: 'Loại giấy tờ',
              cellRenderer: titleRenderer,
            },
            {
              name: 'quantity',
              header: 'Số lượng',
            },
            {
              name: 'status',
              header: 'Trạng thái',
              cellRenderer: statusRenderer,
            },
            {
              name: 'requestDate',
              header: 'Ngày yêu cầu',
              cellRenderer: renderDate,
            },
            {
              name: 'processDate',
              header: 'Ngày xử lý',
              cellRenderer: renderDate,
            },
            {
              name: 'description',
              header: 'Ghi chú',
            },
          ]}
        />
        <Separator className="u-marginVerticalSmall" variant="lighter" />
        <div className="u-text200">
          <ol className="u-paddingLeftNone u-listStylePositionInside">
            <li className="u-fontBold u-marginBottomSmall">
              <span>Hướng dẫn sử dụng Dịch vụ trực tuyến</span>
              <ul className="u-paddingLeftSmall u-listStylePositionInside u-paddingTopExtraSmall u-fontRegular">
                <li>Mỗi sinh viên của Viện được cấp 01 tài khoản với ID và Pass chính là MSSV.</li>
                <li>Sinh viên đăng nhập lần đầu rồi tự đổi Pass của riêng mình.</li>
                <li>Sinh viên điền yêu cầu vào biểu mẫu online.</li>
                <li>Hệ thống sẽ gửi email thông báo cho Sinh viên khi yêu cầu hoàn thành.</li>
                <li className="u-fontMedium">Sinh viên đem theo thẻ Sinh viên lên Văn phòng Viện để nhận.</li>
              </ul>
            </li>

            <li className="u-fontBold u-marginBottomSmall">
              <span>Các biểu mẫu giấy tờ hỗ trợ đăng kí online</span>
              <ul className="u-paddingLeftSmall u-listStylePositionInside u-paddingTopExtraSmall u-fontRegular">
                <li>Giấy Chứng nhận Sinh viên cấp Viện</li>
                <li>Giấy Chứng nhận Sinh viên cấp Trường</li>
                <li>GIấy Giới thiệu cấp Viện</li>
                <li>Giấy Giới thiệu cấp Trường</li>
                <li>In sao bảng điểm</li>
                <li>Giấy chứng nhận Tốt nghiệp</li>
                <li>Các mẫu đơn chung (chỉ áp dụng với đơn có xin dấu của Lãnh đạo Viện)</li>
                <li>Đơn xin chuyển điểm tương đương (SV phải nộp kèm bảng điểm)</li>
              </ul>
            </li>

            <li className="u-fontBold u-marginBottomSmall">
              <span>Liên hệ để nhận giấy tờ tại Văn phòng Viện</span>
              <ul className="u-paddingLeftSmall u-listStylePositionInside u-paddingTopExtraSmall u-fontRegular">
                <li>
                  <span className="u-fontMedium">Cô Nguyễn Thị Hiền</span>
                  : Bảng điểm, Chứng nhận tốt nghiệp cho Sinh viên Việt Nhật, ICT, Chính quy, Đại trà.
                </li>
                <li>
                  <span className="u-fontMedium">Cô Đinh Thu Hương</span>
                  : Giấy xác nhận SV đã đ/ký qua hệ thống trực tuyến và Các giấy tờ khác (Vé xe bus, giấy đăng ký thuê nhà ở đơn vị tập thể, ...)
                </li>
              </ul>
            </li>

            <li className="u-fontBold u-marginBottomSmall">
              <span>Thời gian làm việc tại Văn phòng Viện</span>
              <ul className="u-paddingLeftSmall u-listStylePositionInside u-paddingTopExtraSmall u-fontRegular">
                <li>
                  Nhận yêu cầu (Với các biểu mẫu không yêu cầu online):
                  <span className="u-fontMedium"> Thứ 3, thứ 5 - Sáng từ 8h30 đến 11h30</span>
                </li>
                <li>
                  Trả giấy tờ:
                  <span className="u-fontMedium"> Chiều thứ 3, thứ 5 từ 14h đến 16h30.</span>
                </li>
                <li>
                  Với các giấy tờ cần dấu Trường: Sau khi nhận tại Viện, Sinh viên tự đi lấy dấu tại
                  <span className="u-fontMedium"> phòng Hành chính tổng hợp nhà C1 - 114.</span>
                </li>
              </ul>
            </li>

            <li className="u-fontBold u-marginBottomSmall">
              <span>Lưu ý</span>
              <div className="u-paddingTopExtraSmall u-fontMedium u-fontItalic">Các loại giấy Xác nhận chế độ chính sách, giấy Xác nhận vay vốn, giấy Chứng nhận mất thẻ sinh viên, sinh viên xuống làm việc trực tiếp tại phòng CTCT & CTSV - Nhà C1-104, để được giải quyết.</div>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}
