/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';

import * as actionObjects from 'constants/actions';
import { validateReduxActionKeys } from 'utils/validation';

import rootReducer from './reducers/root.reducer';
import blockingTasksMiddleware from './middlewares/blockingTasksMiddleware';
import promiseMiddleware from './middlewares/promiseMiddleware';
import cacheRequestMiddleware from './middlewares/cacheRequestMiddleware';
import authMiddleware from './middlewares/authMiddleware';
import dataMiddleware from './middlewares/dataMiddleware';
import errorMiddleware from './middlewares/errorMiddleware';

validateReduxActionKeys(actionObjects);

const enhancers = [];
// DO NOT reorder these middlewares
const middlewares = [
  thunkMiddleware,
  blockingTasksMiddleware,
  promiseMiddleware,
  errorMiddleware,
  cacheRequestMiddleware,
  authMiddleware,
  dataMiddleware,
];

if (process.env.REACT_APP_ENV !== 'production') {
  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }
}

const composedEnhancer = compose(
  applyMiddleware(...middlewares),
  ...enhancers,
);

const initStore = () => createStore(rootReducer, {}, composedEnhancer);

const store = initStore();

export default store;
