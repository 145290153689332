import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { EmptyState, SafeAnchor } from '@ahaui/react';
import { withCache, withBlock } from 'store/actions/hoc.action';
import { getClasses } from 'store/actions/user.action';
import { showModal } from 'store/actions/modal.action';
import { ModalKey } from 'constants/common';
import { getTeachingTypeName } from 'utils/message';
import EmptyRows from 'components/Common/EmptyRows';
import FormGroup from 'components/Common/Form/FormGroup';

export default function TimeTable() {
  const dispatch = useDispatch();
  const currentSemester = useSelector(state => state.semester.currentSemester?.semester);
  const allSemesters = useSelector(state => state.semester.semesters);
  const [semester, setSemester] = useState(null);
  const classes = useSelector(state => state.user.classes?.[semester] || null);

  const onTeachingTypeClick = (classInfo) => {
    dispatch(showModal(ModalKey.CLASS_INFO, {
      classInfo,
    }));
  };
  useEffect(() => {
    if (currentSemester && !semester) {
      setSemester(currentSemester);
    }
  }, [currentSemester, semester]);

  useEffect(() => {
    if (semester) {
      dispatch(
        withCache(
          withBlock(getClasses(semester)),
          semester,
        ),
      );
    }
  }, [dispatch, semester]);

  const renderClasses = () => {
    const numberOfCols = 6;
    const numberOfRows = 3;

    if (!classes) {
      return (
        <EmptyRows
          numberOfCols={numberOfCols}
          numberOfRows={numberOfRows}
        />
      );
    }
    if (classes.length === 0) {
      return (
        <tr>
          <td colSpan={numberOfCols} className="u-backgroundWhite">
            <EmptyState name="general" className="u-widthFull">
              <EmptyState.Description>
                {`Không có lớp học nào cho kỳ học ${semester}`}
              </EmptyState.Description>
            </EmptyState>
          </td>
        </tr>
      );
    }
    return classes.map((classInfo, index) => (
      <tr key={classInfo.id}>
        <td className="u-textNoWrap" style={{ width: '1%' }}>{index + 1}</td>
        <td>{classInfo.classId}</td>
        <td>{`${classInfo.courseId} - ${classInfo.displayName}`}</td>
        <td>{classInfo.classType}</td>
        <td>
          {classInfo.classUrl ? (
            <div>
              <SafeAnchor
                onClick={() => onTeachingTypeClick(classInfo)}
              >
                {getTeachingTypeName(classInfo.teachingType)}
              </SafeAnchor>
              {classInfo.accessCode && (
                <div>
                  <span>Code: </span>
                  <strong>{classInfo.accessCode}</strong>
                </div>
              )}
            </div>
          ) : (
            <div>{getTeachingTypeName(classInfo.teachingType)}</div>
          )}
        </td>
        <td>
          {classInfo.beginDate > 0 && classInfo.finishDate > 0 && (
            <div>{`Từ ${moment(classInfo.beginDate).format('DD/MM')} đến ${moment(classInfo.finishDate).format('DD/MM')}`}</div>
          )}
          {classInfo.placeTimeInfo && (
            <div>{classInfo.placeTimeInfo}</div>
          )}
          {classInfo.semesterType && (
            <div>{`Kỳ ${classInfo.semesterType}`}</div>
          )}
          {/* TODO: get teacher's name */}
        </td>
      </tr>
    ));
  };

  return (
    <div className="u-paddingVerticalLarge u-paddingHorizontalSmall">
      <div className="u-backgroundWhite u-border u-roundedLarge u-shadowLarge u-paddingSmall">
        <div className="u-text500 u-fontMedium u-marginBottomSmall">Thời khóa biểu</div>
        <FormGroup
          id="bkeu-semester-select"
          label="Kỳ học"
          name="semester"
          type="select"
          formGroupProps={{
            style: {
              display: 'flex',
              width: 250,
            },
          }}
          formLabelProps={{
            className: 'u-paddingVerticalTiny u-paddingRightExtraSmall',
          }}
          className="u-flexGrow1"
          value={semester}
          onChange={(e) => setSemester(e.target.value)}
          disabled={!semester || !(allSemesters?.length > 0)}
          options={allSemesters?.map(sem => sem.semester)}
        />
        <div className="u-marginVerticalExtraSmall u-borderLeft u-borderRight u-borderBottom u-overflowHorizontalAuto">
          <table className="Table Table--striped Table--hoverable u-backgroundWhite u-textDark u-text200">
            <thead className="u-textNoWrap">
              <tr>
                <th scope="col" className="u-textNoWrap" style={{ width: '1%' }}>#</th>
                <th scope="col">Mã lớp</th>
                <th scope="col">Tên học phần</th>
                <th scope="col">Loại hình</th>
                <th scope="col">Hình thức GD</th>
                <th scope="col" style={{ width: 280 }}>Lịch học</th>
              </tr>
            </thead>
            <tbody>
              {renderClasses()}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
