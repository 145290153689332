import React from 'react';
import moment from 'moment';

export default function FormCNSV({
  formData,
}) {
  const {
    name,
    class: studentClass,
    course,
    code,
    birth,
    city,
    familyAddress,
    currentAddress,
    typeStudy,
  } = formData;

  return (
    <div className="form-doc-panel form-doc-panel-portrait" style={{ marginLeft: 20, marginRight: 15 }}>
      <section className="form-doc-panel-content">
        <div className="doc">
          <section className="u-floatLeft u-textCenter">
            <div className="APB">TRƯỜNG ĐẠI HỌC BÁCH KHOA HÀ NỘI</div>
            <div className="OOB" style={{ fontSize: '11pt' }}>VIỆN CÔNG NGHỆ THÔNG TIN VÀ TRUYỀN THÔNG</div>
            <div className="underline" />
          </section>
          <section className="u-floatRight u-textCenter">
            <p style={{ fontSize: '12pt', fontWeight: 'bold', marginBottom: 0 }}>CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
            <p style={{ fontSize: '13pt', fontWeight: 'bold', marginBottom: 0 }}>Độc lập – Tự do – Hạnh phúc</p>
            <div className="underline" />
          </section>
          <section className="u-textCenter u-clearBoth">
            <p
              style={{
                fontSize: '14pt', fontWeight: 'bold', textAlign: 'center', marginTop: 7, marginBottom: 7,
              }}
            >
              GIẤY CHỨNG NHẬN SINH VIÊN
            </p>
            <div className="POB">VIỆN CÔNG NGHỆ THÔNG TIN VÀ TRUYỀN THÔNG</div>
          </section>
          <div className="LOB" style={{ fontSize: '13pt', lineHeight: 1.5 }}>
            <div style={{ marginTop: 20 }}>
              <div>
                <span>Chứng nhận anh/chị:&nbsp;</span>
                <div style={{ display: 'inline-block' }}><div className="gwt-HTML" style={{ minWidth: 135 }}>{name}</div></div>
                <span>&nbsp;&nbsp;&nbsp;Số hiệu sinh viên:&nbsp;</span>
                <div style={{ display: 'inline-block' }}><div className="gwt-HTML" style={{ minWidth: '100%' }}>{code}</div></div>
              </div>
              <div>
                Sinh ngày:&nbsp;
                <div style={{ display: 'inline-block' }}><div className="gwt-HTML" style={{ minWidth: 200 }}>{moment(birth).format('DD-MM-YYYY')}</div></div>
&nbsp;&nbsp;Tại thành phố/tỉnh:&nbsp;
                <div style={{ display: 'inline-block' }}><div className="gwt-HTML" style={{ minWidth: '100%' }}>{city}</div></div>
              </div>
              <div>
                Địa chỉ gia đình:&nbsp;
                <div style={{ display: 'inline-block' }}><div className="gwt-HTML" style={{ minWidth: '100%' }}>{familyAddress}</div></div>
              </div>
              <div>
                Chỗ ở hiện nay:&nbsp;
                <div style={{ display: 'inline-block' }}><div className="gwt-HTML" style={{ minWidth: '100%' }}>{currentAddress}</div></div>
              </div>
              <div>
                Là sinh viên lớp:&nbsp;
                <div style={{ display: 'inline-block' }}><div className="gwt-HTML" style={{ minWidth: '100%' }}>{studentClass}</div></div>
&nbsp;&nbsp;&nbsp;Khóa:&nbsp;
                <div style={{ display: 'inline-block' }}><div className="gwt-HTML" style={{ minWidth: '100%' }}>{course}</div></div>
&nbsp;&nbsp;&nbsp;Thuộc hệ:&nbsp;
                <div style={{ display: 'inline-block' }}><div className="gwt-HTML" style={{ display: 'inline-block' }}>{typeStudy}</div></div>
              </div>
            </div>
            <p style={{ marginTop: 5 }}>
              <i><b>Giấy này có giá trị 30 ngày kể từ ngày ký</b></i>
            </p>
            <p />
            <section className="u-floatRight u-textCenter">
              <div className="NOB">
                Hà Nội, ngày .... tháng .... năm ....
              </div>
              <div style={{ paddingBottom: 20 }}><div className="MOB">VIỆN TRƯỞNG</div></div>
            </section>
          </div>
        </div>
      </section>
    </div>
  );
}
