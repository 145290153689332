import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon } from '@ahaui/react';
import { getProjectPlagiarismRequests } from 'store/actions/project.action';
import { withBlock } from 'store/actions/hoc.action';
import { renderDateTime } from 'components/Common/Table/renderers';
import Table from 'components/Common/Table';
import ExternalLink from 'components/Common/ExternalLink';

export default function ResultTab() {
  const dispatch = useDispatch();
  const plagiarismRequests = useSelector(state => state.project.plagiarismRequests);
  const [reloading, setReloading] = useState(false);

  const onReloadClick = async () => {
    setReloading(true);
    await dispatch(withBlock(getProjectPlagiarismRequests()));
    setReloading(false);
  };

  const renderExternalLink = ({
    key,
    rowData,
    columnConfig,
  }) => (
    <td key={key}>
      <ExternalLink
        href={rowData[columnConfig.name]}
      >
        {columnConfig.header}
      </ExternalLink>
    </td>
  );

  const renderOriginalFile = ({
    key,
    rowData,
    columnConfig,
  }) => {
    const fileUrlParts = rowData.fileUrl?.split('/');
    const fileName = fileUrlParts?.[fileUrlParts.length - 1];

    return (
      <td key={key}>
        <ExternalLink
          href={rowData.fileUrl}
        >
          {fileName ?? columnConfig.header}
        </ExternalLink>
      </td>
    );
  };

  const renderPercentage = ({
    key,
    rowData,
  }) => {
    let percent = rowData.result;
    if (percent !== -1) {
      percent = Math.round((percent + Number.EPSILON) * 100) / 100;
    }

    let color;
    if (percent === -1) {
      color = 'u-textGray';
    } else if (percent < 2) {
      color = 'u-textPositive';
    } else if (percent <= 15) {
      color = 'u-textWarning';
    } else {
      color = 'u-textNegative';
    }

    return (
      <td key={key} className={color}>
        {percent === -1 ? 'N/A' : `${percent}%`}
      </td>
    );
  };

  useEffect(() => {
    dispatch(withBlock(getProjectPlagiarismRequests()));
  }, [dispatch]);

  return (
    <div className="u-flex u-flexColumn">
      <div className="u-alignSelfEnd u-marginBottomSmall">
        <Button
          size="small"
          variant="primary_outline"
          onClick={onReloadClick}
          disabled={reloading}
        >
          <Button.Icon>
            <Icon name="refresh" size="extraSmall" />
          </Button.Icon>
          <Button.Label>Tải lại</Button.Label>
        </Button>
      </div>
      <Table
        columns={[
          {
            name: 'fileUrl',
            header: 'File gốc',
            cellRenderer: renderOriginalFile,
          },
          {
            name: 'highlightFile',
            header: 'Highlight',
            cellRenderer: renderExternalLink,
          },
          {
            name: 'reportFilePath',
            header: 'File Report',
            cellRenderer: renderExternalLink,
          },
          {
            name: 'onlineReport',
            header: 'Online Report',
            cellRenderer: renderExternalLink,
          },
          {
            name: 'result',
            header: 'Trùng lặp',
            cellRenderer: renderPercentage,
          },
          {
            name: 'requestTime',
            header: 'Yêu cầu',
            cellRenderer: renderDateTime,
          },
          {
            name: 'responseTime',
            header: 'Kết quả',
            cellRenderer: renderDateTime,
          },
        ]}
        rows={plagiarismRequests}
      />
    </div>
  );
}
