import React, { useState } from 'react';
import TabGroup from 'components/Common/TabGroup';

import SchoolOrientations from './SchoolOrientations';
import CompanyOrientations from './CompanyOrientations';

const TabKey = {
  SCHOOL: 'SCHOOL',
  COMPANY: 'COMPANY',
};

const tabsConfig = [
  {
    eventKey: TabKey.SCHOOL,
    label: 'Đề tài của Viện',
  },
  {
    eventKey: TabKey.COMPANY,
    label: 'Đề tài Doanh nghiệp',
  },
];

export default function ProjectOrientations() {
  const [currentTab, setCurrentTab] = useState(TabKey.SCHOOL);

  return (
    <div className="u-paddingVerticalLarge u-paddingHorizontalSmall">
      <div className="u-backgroundWhite u-border u-roundedLarge u-shadowLarge u-paddingSmall">
        <div className="u-text500 u-fontMedium u-marginBottomSmall">Định hướng đề tài</div>
        <TabGroup
          currentTab={currentTab}
          onSelectTab={setCurrentTab}
          tabsConfig={tabsConfig}
          className="u-marginBottomSmall"
        />
        {currentTab === TabKey.SCHOOL && (
          <SchoolOrientations />
        )}
        {currentTab === TabKey.COMPANY && (
          <CompanyOrientations />
        )}
      </div>
    </div>
  );
}
