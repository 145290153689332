import React from 'react';
import { useForm } from 'react-hook-form';
import { findField } from 'utils/object';
import FormGroup from 'components/Common/Form/FormGroup';
import withPropsWaiting from 'components/HOCs/withPropsWaiting';

const InfoFields = [
  {
    name: 'fullName',
    label: 'Họ và Tên',
    type: 'text',
    disabled: true,
  },
  {
    name: 'studentId',
    label: 'MSSV',
    type: 'text',
    disabled: true,
  },
  {
    name: 'studentYear',
    label: 'Khóa',
    type: 'text',
    disabled: true,
  },
  {
    name: 'studentClass.name',
    label: 'Lớp',
    type: 'text',
    disabled: true,
  },
  {
    name: 'studentClass.teacher.fullName',
    label: 'Quản lý lớp',
    type: 'text',
    disabled: true,
  },
];

const propsChecker = ({
  userInfo,
}) => !!userInfo;

function UserInfoEditor({
  userInfo,
}) {
  const formHookInfo = useForm({
    criteriaMode: 'all',
    defaultValues: InfoFields.reduce((values, currentField) => ({
      ...values,
      [currentField.name]: findField(userInfo, currentField.name),
    }), {}),
  });

  const renderRow = ({
    name,
    ...formInputProps
  }) => (
    <FormGroup
      id={`bkeu-user-info-${name}`}
      formHookInfo={formHookInfo}
      name={name}
      {...formInputProps}
      formGroupProps={{
        className: 'u-sizeFull lg:u-size1of2 u-flexWrap',
        style: {
          display: 'flex',
        },
      }}
      formLabelProps={{
        className: 'lg:u-paddingVerticalTiny u-sizeFull lg:u-size4of12',
      }}
      className="u-flexGrow1"
      style={{
        width: 'auto',
      }}
    />
  );

  return (
    <div className="Container u-paddingVerticalLarge">
      <div className="u-backgroundWhite u-border u-roundedLarge u-shadowLarge u-paddingSmall u-marginBottomMedium">
        <div>
          <div className="u-text500 u-fontMedium u-marginBottomSmall">Thông tin cá nhân</div>
          <div className="Grid Grid--smallGutter">
            {InfoFields.map(field => renderRow(field))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withPropsWaiting(UserInfoEditor, propsChecker);
