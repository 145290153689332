import { getSuccessAction, getFailureAction } from 'utils/redux';

export default ({ dispatch, getState }) => (next) => (action) => {
  // If there's no promise, call next step
  if (!action.promise) {
    return next(action);
  }

  // If there's a promise
  const {
    promise, type, taskKey, ...rest
  } = action;

  const beginAction = type;
  const successAction = getSuccessAction(type);
  const failureAction = getFailureAction(type);

  let p = promise;
  // If promise is nested in a function
  if (typeof promise === 'function') {
    p = promise(dispatch, getState);
  }

  const extraData = {
    unblockTaskKey: taskKey,
  };

  // Pass beginAction to the next step, except promise
  next({ type: beginAction, taskKey, ...rest });

  return p.then((result) => {
    // Dispatch successAction
    dispatch({
      type: successAction,
      ...rest,
      payload: result,
      extraPayload: rest.payload,
      ...extraData,
    });

    const response = { success: true, result };
    return response;
  }).catch((error) => {
    console.error('Promise FAILED:', error.data, error);
    // Dispatch failureAction
    dispatch({
      type: failureAction,
      ...rest,
      payload: error,
      extraPayload: rest.payload,
      ...extraData,
    });

    const response = { success: false, error };
    return response;
  });
};
