import FilterContainer from './FilterContainer';
import FilterSearchString from './FilterSearchString';
import FilterSelectBox from './FilterSelectBox';

function Filter() {}

Filter.Container = FilterContainer;
Filter.SearchString = FilterSearchString;
Filter.SelectBox = FilterSelectBox;

export default Filter;
