import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withCache, withBlock } from 'store/actions/hoc.action';
import { getProjects, getProjectRequests } from 'store/actions/project.action';

import ProjectsSection from './ProjectsSection';
import ProjectRequestsSection from './ProjectRequestsSection';

export default function StudentProjects() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(withCache(withBlock(getProjects())));
    dispatch(withCache(withBlock(getProjectRequests())));
  }, [dispatch]);

  return (
    <div className="u-paddingVerticalLarge u-paddingHorizontalSmall u-flex u-flexColumn">
      <ProjectsSection />
      <ProjectRequestsSection />
    </div>
  );
}
