import React, { useCallback } from 'react';
import FormInputGroup from './FormInputGroup';
import FormFileGroup from './FormFileGroup';
import FormSelectGroup from './FormSelectGroup';
import FormTagInputGroup from './FormTagInputGroup';
import FormFeedback from './FormFeedback';

export default function FormGroup({
  formHookInfo,
  ...props
}) {
  let register;
  let errors;
  let watch;
  let setValue;

  if (formHookInfo) {
    register = formHookInfo.register;
    errors = formHookInfo.formState.errors;
    watch = formHookInfo.watch;
    setValue = formHookInfo.setValue;
  }

  const { type } = props;

  const renderFeedback = useCallback((feedback) => {
    if (!feedback) return null;
    if (typeof feedback === 'string') {
      return (
        <FormFeedback value={feedback} />
      );
    }
    if (feedback?.value) {
      return (
        <FormFeedback {...feedback} />
      );
    }
    return null;
  }, []);

  const renderInput = () => {
    if (type === 'file') {
      return (
        <FormFileGroup
          renderFeedback={renderFeedback}
          register={register}
          errors={errors}
          watch={watch}
          {...props}
        />
      );
    }

    if (type === 'select') {
      return (
        <FormSelectGroup
          renderFeedback={renderFeedback}
          register={register}
          errors={errors}
          {...props}
        />
      );
    }

    if (type === 'tagInput') {
      return (
        <FormTagInputGroup
          renderFeedback={renderFeedback}
          register={register}
          errors={errors}
          watch={watch}
          setValue={setValue}
          {...props}
        />
      );
    }

    return (
      <FormInputGroup
        renderFeedback={renderFeedback}
        register={register}
        errors={errors}
        {...props}
      />
    );
  };

  return (
    <>
      {renderInput()}
      {renderFeedback()}
    </>
  );
}
