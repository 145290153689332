import React from 'react';
import { Message, Separator } from '@ahaui/react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from 'constants/message';
import FormGroup from 'components/Common/Form/FormGroup';

const FormFields = [
  {
    name: 'Chọn Nguyện vọng',
    label: 'Chọn Nguyện vọng',
    type: 'text',
    required: ErrorMessage.REQUIRED_FIELD,
  },
  {
    type: 'separator',
    label: 'Thông tin',
  },
  {
    name: 'Điểm tiếng Anh',
    label: 'Điểm tiếng Anh',
    type: 'number',
    required: ErrorMessage.REQUIRED_FIELD,
    disabled: true,
  },
  {
    name: 'Số tín chỉ nợ',
    label: 'Số tín chỉ nợ',
    type: 'number',
    required: ErrorMessage.REQUIRED_FIELD,
    disabled: true,
  },
  {
    name: 'Upload CV',
    label: 'Upload CV',
    type: 'file',
    required: ErrorMessage.REQUIRED_FIELD,
  },
  {
    name: 'Chọn Nguyện vọng',
    label: 'Chọn Nguyện vọng',
    type: 'text',
    required: ErrorMessage.REQUIRED_FIELD,
  },
  {
    name: 'Hệ đào tạo',
    label: 'Hệ đào tạo',
    type: 'text',
    required: ErrorMessage.REQUIRED_FIELD,
  },
  {
    name: 'Môn Đồ án',
    label: 'Môn Đồ án',
    type: 'text',
    required: ErrorMessage.REQUIRED_FIELD,
  },
  {
    name: 'Học kỳ',
    label: 'Học kỳ',
    type: 'text',
    required: ErrorMessage.REQUIRED_FIELD,
  },
  {
    name: 'Đăng ký HP trên SIS',
    label: 'Đăng ký HP trên SIS',
    type: 'text',
    required: ErrorMessage.REQUIRED_FIELD,
  },
  {
    name: 'Thời gian',
    label: 'Thời gian',
    type: 'text',
    required: ErrorMessage.REQUIRED_FIELD,
  },
  {
    name: 'Chọn Đơn vị',
    label: 'Chọn Đơn vị',
    type: 'text',
    required: ErrorMessage.REQUIRED_FIELD,
  },
  {
    name: 'Chọn Đơn vị',
    label: 'Chọn Đơn vị',
    type: 'text',
  },
  {
    name: 'Ghi chú của SV (nếu có)',
    label: 'Ghi chú của SV (nếu có)',
    as: 'textarea',
    type: 'text',
    rows: 3,
  },
];

export default function ProjectRegistration() {
  const formHookInfo = useForm({
    criteriaMode: 'all',
  });

  const renderRow = ({
    name,
    type,
    ...formInputProps
  }) => {
    if (type === 'separator') {
      return (
        <Separator
          className="u-paddingHorizontalExtraSmall u-marginBottomSmall"
          lineType="dashed"
          {...formInputProps}
        />
      );
    }

    return (
      <FormGroup
        id={`bkeu-user-info-${name}`}
        formHookInfo={formHookInfo}
        name={name}
        type={type}
        {...formInputProps}
        formGroupProps={{
          className: 'u-sizeFull u-flexWrap',
          style: {
            display: 'flex',
          },
        }}
        formLabelProps={{
          className: 'lg:u-paddingVerticalTiny u-sizeFull lg:u-size4of12 lg:u-textRight lg:u-paddingRightExtraSmall u-flexShrink0',
        }}
        formContextProps={{
          className: 'u-sizeFull lg:u-offset4of12 lg:u-size8of12',
        }}
        className="u-flexGrow1"
        style={{
          width: 'auto',
        }}
      />
    );
  };

  return (
    <div className="u-backgroundDarkGray u-flexGrow1">
      <div className="Container u-paddingVerticalLarge">
        <div className="u-backgroundWhite u-border u-roundedLarge u-shadowLarge u-paddingSmall u-marginBottomMedium">
          <div>
            <div className="u-text500 u-fontMedium u-marginBottomSmall">Đăng ký nguyện vọng kỳ 202xx</div>
            <div className="u-marginBottomSmall">
              <Message variant="warning">
                <Message.Container>
                  <Message.Title>
                    Lưu ý
                  </Message.Title>
                  <Message.Content>
                    <ol className="u-paddingLeftNone u-marginBottomNone u-listStylePositionInside">
                      <li>Tra cứu Danh sách Đề tại Tại đây.</li>
                      <li>Chức năng này chỉ hỗ trợ đăng ký nguyện vọng/GVHD, SV phải hoàn thành đăng ký Học phần tương ứng trên Hệ thống SIS.</li>
                    </ol>
                  </Message.Content>
                </Message.Container>
              </Message>
            </div>
            <div className="Grid Grid--smallGutter">
              {FormFields.map(field => renderRow(field))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
