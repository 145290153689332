import React from 'react';
import image1 from 'assets/images/landing/teacher/1.png';
import image2 from 'assets/images/landing/teacher/2.png';
import image3 from 'assets/images/landing/teacher/3.png';
import image4 from 'assets/images/landing/teacher/4.png';
import image5 from 'assets/images/landing/teacher/5.png';
import image6 from 'assets/images/landing/teacher/6.png';
import image7 from 'assets/images/landing/teacher/7.png';
import image8 from 'assets/images/landing/teacher/8.png';

export default function TeacherSection() {
  return (
    <section
      className="u-backgroundWhite u-positionRelative u-paddingVerticalExtraLarge"
    >
      <div className="Container">
        <h2 className="u-text800 lg:u-text900 u-textCenter lg:u-marginBottomLarge">
          Dịch vụ cho Giảng viên
        </h2>
        <div className="Grid Grid--smallGutter">
          {[
            { text: 'Thời khoá biểu, phân công giảng dạy các lớp Lý thuyết, Thực hành, Thí nghiệm', img: image1 },
            { text: 'Phân công Đồ án tốt nghiệp và Đồ án môn học', img: image2 },
            { text: 'Tra cứu thông tin Sinh viên', img: image3 },
            { text: 'Tra cứu thông tin học phần', img: image4 },
            { text: 'Quản lý tài liệu, biểu mẫu', img: image5 },
            { text: 'Kê khai khối lượng giảng dạy', img: image6 },
            { text: 'Thống kê, tổng hợp số liệu', img: image7 },
            { text: 'Đồng bộ Thời khoá biểu với Google Calendar', img: image8 },
          ].map(({ text, img }) => (
            <div
              key={text}
              className="u-size1of2 lg:u-size1of4 u-paddingBottomSmall"
            >
              <div
                className="u-flex u-flexColumn u-alignItemsCenter u-justifyContentStart u-shadowMedium u-paddingSmall u-roundedLarge u-heightFull"
              >
                <div
                  className="u-marginBottomSmall"
                  style={{ minHeight: 65 }}
                >
                  <img
                    src={img}
                    style={{ width: 50 }}
                    alt=""
                  />
                </div>
                <div className="u-textCenter">{text}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
