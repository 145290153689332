import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ModalKey } from 'constants/common';
import { showModal } from 'store/actions/modal.action';

import LogInModal from './LogInModal';
import ForgotPasswordModal from './ForgotPasswordModal';
import GoogleDocsViewerModal from './GoogleDocsViewerModal';
import CompanyInfoModal from './CompanyInfoModal';
import ClassInfoModal from './ClassInfoModal';
import FormModal from './FormModal';

const ModalMap = {
  [ModalKey.LOG_IN]: LogInModal,
  [ModalKey.FORGOT_PASSWORD]: ForgotPasswordModal,
  [ModalKey.GOOGLE_DOCS_VIEWER]: GoogleDocsViewerModal,
  [ModalKey.COMPANY_INFO]: CompanyInfoModal,
  [ModalKey.CLASS_INFO]: ClassInfoModal,
  [ModalKey.FORM]: FormModal,
};

export default function ModalContainer() {
  const dispatch = useDispatch();
  const { modalKey, modalProps } = useSelector(state => state.modal);

  const handleModalClose = () => {
    dispatch(showModal(null));
  };

  if (!modalKey) return null;
  const Renderer = ModalMap[modalKey];
  if (!Renderer) return null;

  return (
    <Renderer
      {...modalProps}
      onModalClose={() => {
        if (modalProps?.onModalClose) {
          modalProps?.onModalClose();
        }
        handleModalClose();
      }}
    />
  );
}
