import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withCache, withBlock } from 'store/actions/hoc.action';
import { getProjectTopicsSchool } from 'store/actions/project.action';
import { getProjectTypeName } from 'utils/message';
import useFilter from 'hooks/useFilter';
import Table from 'components/Common/Table';
import ToggleShowWrapper from 'components/Common/ToggleShowWrapper';
import Filter from 'components/Common/Filter';

export default function SchoolOrientations() {
  const dispatch = useDispatch();
  const projectTopicsSchool = useSelector(state => state.project.projectTopicsSchool);

  const {
    searchPattern,
    onSearchPatternChange,
    filteredItems: filteredProjectTopicsSchool,
  } = useFilter({
    items: projectTopicsSchool,
    options: {
      keys: ['title', 'description'],
      distance: 500,
    },
  });

  useEffect(() => {
    dispatch(withCache(withBlock(getProjectTopicsSchool())));
  }, [dispatch]);

  return (
    <div>
      <Filter.Container>
        <Filter.SearchString
          name="search-by-title"
          value={searchPattern}
          onChange={onSearchPatternChange}
        />
      </Filter.Container>
      <Table
        columns={[
          {
            name: 'Giảng viên',
            cellRenderer: ({
              key,
              rowData,
            }) => (
              <td key={key}>
                <div className="u-fontMedium">{rowData.teacherName}</div>
                {/* TODO: EducationUnit */}
                <div>{rowData.teacherEmail}</div>
                {rowData.teacherPhoneNumber && (
                  <div>{rowData.teacherPhoneNumber}</div>
                )}
              </td>
            ),
          },
          {
            name: 'Hướng đề tài',
            cellRenderer: ({
              key,
              rowData,
            }) => (
              <td key={key}>
                <div className="u-fontMedium u-textPrimary">{rowData.title}</div>
                {rowData.types?.length > 0 && (
                  <div>
                    <span>Loại đề tài: </span>
                    <span className="u-fontMedium">{rowData.types.map(type => getProjectTypeName(type, true)).join(', ')}</span>
                  </div>
                )}
                {/* TODO: EduProgram */}
                <div>
                  <span>Số SV: </span>
                  <span className="u-fontMedium">{rowData.studentNum}</span>
                </div>
                {rowData.description && (
                  <ToggleShowWrapper>
                    <div className="u-paddingVerticalExtraSmall u-textPreWrap">{rowData.description}</div>
                  </ToggleShowWrapper>
                )}
              </td>
            ),
          },
        ]}
        rows={filteredProjectTopicsSchool}
      />
    </div>
  );
}
