import { FormAction } from 'constants/actions';
import { getSuccessAction } from 'utils/redux';

const getInitialState = () => ({
  forms: null,
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case getSuccessAction(FormAction.GET_FORMS): {
      const forms = action.payload.map((form) => {
        let data = {};
        try {
          data = JSON.parse(form.data);
        } catch (error) {
          console.error(error);
        }

        return {
          ...form,
          data,
        };
      });

      return {
        ...state,
        forms,
      };
    }

    default:
      break;
  }
  return state;
};
