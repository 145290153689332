import { v4 as uuidv4 } from 'uuid';
import { blockTask, unblockTask } from 'store/actions/app.action';

export default ({ dispatch }) => next => (action) => {
  const { shouldBlock, unblockTaskKey, ...rest } = action;

  if (unblockTaskKey) {
    dispatch(unblockTask(unblockTaskKey));
  }

  let taskKey;
  if (shouldBlock) {
    taskKey = `${action.type}-${uuidv4()}`;
    dispatch(blockTask(taskKey));
  }

  return next({ ...rest, taskKey });
};
