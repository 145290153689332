import React from 'react';
import { EmptyState, SafeAnchor } from '@ahaui/react';
import { useDispatch, useSelector } from 'react-redux';
import orderBy from 'lodash/orderBy';
import { showModal } from 'store/actions/modal.action';
import { ModalKey } from 'constants/common';
import EmptyRows from 'components/Common/EmptyRows';

export default function ProjectsSection() {
  const dispatch = useDispatch();
  const projects = useSelector(state => (
    state.project.projects
      ? orderBy(state.project.projects, 'semester', 'desc')
      : null
  ));
  const companies = useSelector(state => state.company.companies);

  const onCompanyClick = (companyId) => {
    dispatch(showModal(ModalKey.COMPANY_INFO, {
      companyId,
    }));
  };

  const renderProjects = () => {
    const numberOfCols = 8;
    const numberOfRows = 3;

    if (!projects) {
      return (
        <EmptyRows
          numberOfCols={numberOfCols}
          numberOfRows={numberOfRows}
        />
      );
    }
    if (projects.length === 0) {
      return (
        <tr>
          <td colSpan={numberOfCols} className="u-backgroundWhite">
            <EmptyState name="general" className="u-widthFull">
              <EmptyState.Description>
                Không có đồ án nào
              </EmptyState.Description>
            </EmptyState>
          </td>
        </tr>
      );
    }
    return projects.map((project, index) => (
      <tr key={project.id}>
        <td className="u-textNoWrap" style={{ width: '1%' }}>{index + 1}</td>
        <td>{project.semester}</td>
        <td>{`${project.classId} - ${project.courseId}`}</td>
        <td>{project.projectName}</td>
        <td>{project.projectTitle}</td>
        {/* TODO: project.feedbackValueOfStudent */}
        <td>N/A</td>
        <td className="u-textNoWrap">{project.teacherName}</td>
        <td>
          {project.companyId > 0 && companies && (
            companies[project.companyId] ? (
              <SafeAnchor
                onClick={() => onCompanyClick(project.companyId)}
              >
                {companies[project.companyId].name}
              </SafeAnchor>
            ) : (
              'Đang tải...'
            )
          )}
          {!(project.companyId > 0) && 'N/A'}
        </td>
      </tr>
    ));
  };

  return (
    <div className="u-backgroundWhite u-border u-roundedLarge u-shadowLarge u-paddingSmall u-marginBottomMedium">
      <div className="u-text500 u-fontMedium u-marginBottomSmall">Danh sách đồ án</div>
      <div className="u-marginVerticalExtraSmall u-borderLeft u-borderRight u-borderBottom u-overflowHorizontalAuto">
        <table className="Table Table--striped Table--hoverable u-backgroundWhite u-textDark u-text200">
          <thead className="u-textNoWrap">
            <tr>
              <th scope="col" className="u-textNoWrap" style={{ width: '1%' }}>#</th>
              <th scope="col">Kỳ</th>
              <th scope="col">Mã Lớp/HP</th>
              <th scope="col">Tên Học phần</th>
              <th scope="col">Tên Đề tài</th>
              <th scope="col">Điểm</th>
              <th scope="col">GVHD</th>
              <th scope="col">Công ty</th>
            </tr>
          </thead>
          <tbody>
            {renderProjects()}
          </tbody>
        </table>
      </div>
    </div>
  );
}
