import React, { useState, useEffect } from 'react';
import {
  Header as HeaderUI, Logo, Button,
} from '@ahaui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useThrottledCallback } from 'use-debounce';
import { Link } from 'react-router-dom';
import { ModalKey, LOGO_RATIO } from 'constants/common';
import { showModal } from 'store/actions/modal.action';

import UserDropdown from './UserDropdown';
import NavBar from './NavBar';
import SemesterInfo from './SemesterInfo';

export default function Header() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(state => state.user.isLoggedIn);
  const [minimized, setMinimized] = useState(false);

  const onLogInClick = () => {
    dispatch(showModal(ModalKey.LOG_IN));
  };

  const throttledCheckMinimized = useThrottledCallback(() => {
    const shouldMinimized = document.getElementById('scroll-container')?.scrollTop >= 200;
    setMinimized(shouldMinimized);
  }, 100);

  useEffect(() => {
    throttledCheckMinimized();

    document.getElementById('scroll-container')
      ?.addEventListener('scroll', throttledCheckMinimized);

    return () => {
      document.getElementById('scroll-container')
        ?.removeEventListener('scroll', throttledCheckMinimized);
    };
  }, [throttledCheckMinimized]);

  return (
    <header className="u-positionRelative u-zIndexPositive u-shadowSmall">
      <HeaderUI
        fullWidth
        className="u-borderBottom u-transitionAll u-easeInOut u-duration200"
        style={{
          paddingTop: minimized ? 8 : undefined,
          paddingBottom: minimized ? 8 : undefined,
          minHeight: minimized ? 49 : 113,
        }}
      >
        <HeaderUI.Brand>
          <Link to="/">
            <div style={{ width: 55 }}>
              <div
                className="u-transitionAll u-easeInOut u-duration200"
                style={{
                  height: minimized ? 32 : 80,
                  width: minimized ? 32 * LOGO_RATIO : 80 * LOGO_RATIO,
                }}
              >
                <Logo
                  name="hustPng"
                  className="u-widthFull u-heightFull"
                  height="100%"
                  width="100%"
                />
              </div>
            </div>
          </Link>
        </HeaderUI.Brand>
        <HeaderUI.Main>
          <HeaderUI.Left>
            <div className="u-flex u-flexColumn">
              <div className="u-text300 lg:u-text500 u-fontBold">
                HỆ THỐNG QUẢN LÝ ĐẠI HỌC TRỰC TUYẾN
              </div>
              {!minimized && (
                <div className="u-text200 u-hidden lg:u-block u-overflowHidden u-marginTopExtraSmall">
                  <div>
                    TRƯỜNG ĐẠI HỌC BÁCH KHOA HÀ NỘI
                  </div>
                  <div>
                    VIỆN CÔNG NGHỆ THÔNG TIN VÀ TRUYỀN THÔNG
                  </div>
                </div>
              )}
            </div>
          </HeaderUI.Left>
          <HeaderUI.Right>
            <div className="u-flex u-flexColumn u-alignItemsEnd">
              {isLoggedIn ? (
                <UserDropdown />
              ) : (
                <Button
                  variant="primary"
                  onClick={onLogInClick}
                >
                  Đăng nhập
                </Button>
              )}
              <SemesterInfo />
            </div>
          </HeaderUI.Right>
        </HeaderUI.Main>
      </HeaderUI>
      {isLoggedIn && (
        <NavBar />
      )}
    </header>
  );
}
