import React from 'react';

export default function WalkthroughSection() {
  return (
    <section
      className="u-backgroundWhite u-positionRelative u-paddingVerticalExtraLarge"
    >
      <div className="Container">
        <h2 className="u-text800 lg:u-text900 u-textCenter lg:u-marginBottomLarge">
          Hướng dẫn sử dụng Dịch vụ Trực tuyến
        </h2>
        <div className="Grid Grid--smallGutter">
          {[
            {
              title: 'Nhận tài khoản',
              description: 'Mỗi sinh viên được cấp một tài khoản với tài khoản và mật khẩu mặc định là mã số sinh viên',
              color: '#ff8c66',
            },
            {
              title: 'Gửi yêu cầu',
              description: 'Sinh viên đăng nhập, điền biểu mẫu yêu cầu và nộp trên hệ thống Online',
              color: '#ffe380',
            },
            {
              title: 'Xử lý yêu cầu',
              description: 'Giáo vụ Viện nhận yêu cầu online, xử lý và thông báo qua email khi hoàn thành',
              color: '#9ee0dc',
            },
            {
              title: 'Nhận kết quả',
              description: 'Khi nhận được thông báo yêu cầu xử lý thành công, Sinh viên lên Văn phòng Viện để nhận kết quả.',
              color: '#a6e467',
            },
          ].map(({ title, description, color }, index) => (
            <div
              key={title}
              className="u-sizeFull md:u-size1of4 u-paddingBottomSmall"
            >
              <div
                className="u-flex u-flexColumn u-alignItemsCenter u-justifyContentStart u-shadowMedium u-paddingSmall u-paddingTopLarge u-roundedLarge u-heightFull u-positionRelative u-overflowHidden"
              >
                <div
                  className="u-positionAbsolute u-positionTop u-positionLeft u-text600 u-fontBold"
                  style={{
                    height: 70,
                    width: 70,
                    paddingTop: 6,
                    paddingLeft: 12,
                    backgroundImage: `linear-gradient(to bottom right, ${color} 50%, #ffffff 50%)`,
                  }}
                >
                  {index + 1}
                </div>
                <div className="u-positionRelative">
                  <div className="u-textCenter u-marginBottomSmall u-text400 u-fontMedium">{title}</div>
                  <div className="u-textCenter">{description}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
