import { AppAction, UserAction } from 'constants/actions';
import configs from 'configs';
import { getSuccessAction, getFailureAction } from 'utils/redux';

const getInitialState = () => ({
  blockingTasks: [],
  cachedRequests: {},
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case getSuccessAction(UserAction.LOG_OUT):
    case getFailureAction(UserAction.LOG_OUT): {
      return getInitialState();
    }

    case AppAction.BLOCK_TASK: {
      return {
        ...state,
        blockingTasks: [
          ...new Set([
            ...state.blockingTasks,
            action.payload,
          ]),
        ],
      };
    }

    case AppAction.UNBLOCK_TASK: {
      return {
        ...state,
        blockingTasks: state.blockingTasks.filter(taskKey => taskKey !== action.payload),
      };
    }

    case AppAction.CACHE_REQUEST: {
      return {
        ...state,
        cachedRequests: {
          ...state.cachedRequests,
          [action.payload]: Date.now() + (configs.requestCachingDuration || 0),
        },
      };
    }

    case AppAction.CACHE_REQUESTS: {
      const cacheExpiration = Date.now() + (configs.requestCachingDuration || 0);
      const newCaches = {};
      action.payload.forEach((actionKey) => {
        newCaches[actionKey] = cacheExpiration;
      });
      return {
        ...state,
        cachedRequests: {
          ...state.cachedRequests,
          ...newCaches,
        },
      };
    }

    default:
      break;
  }

  return state;
};
