import React, { useState } from 'react';
import {
  Dropdown, Icon, Separator, Avatar,
} from '@ahaui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import formatter from 'utils/formatter';
import { withBlock } from 'store/actions/hoc.action';
import { logOut } from 'store/actions/user.action';

export default function UserDropdown() {
  const dispatch = useDispatch();
  const history = useHistory();
  const userInfo = useSelector(state => state.user.info);
  const [show, setShow] = useState(false);

  const onUserInfoMenuClick = () => {
    history.push('/user-info');
    setShow(false);
  };

  const onChangePasswordMenuClick = () => {
    setShow(false);
  };

  const onLogOutClick = () => {
    setShow(false);
    dispatch(withBlock(logOut()));
  };

  return (
    <Dropdown
      show={show}
      onToggle={() => setShow(show => !show)}
      alignRight
      className="u-marginLeftExtraSmall"
    >
      <Dropdown.Toggle className="u-textLight u-lineHeightNone">
        <Icon name="contact" size="large" />
      </Dropdown.Toggle>
      <Dropdown.Container
        className="u-paddingVerticalExtraSmall"
        additionalStyles={{ minWidth: 350 }}
      >
        <div className="u-flex u-paddingExtraSmall">
          <Avatar
            size="large"
            text={formatter.getShortName(userInfo?.fullName)}
            className="u-backgroundPrimaryLight u-text300 u-flexShrink0"
          />
          <div className="u-flex u-flexGrow1 u-flexColumn u-paddingLeftExtraSmall">
            <div className="u-fontMedium">{userInfo?.fullName}</div>
            <div>{userInfo?.email}</div>
          </div>
        </div>
        <Separator variant="lighter" />
        <Dropdown.Item
          className="u-paddingVerticalExtraSmall u-cursorPointer"
          onClick={onUserInfoMenuClick}
        >
          <Icon name="contact" size="small" className="u-textGray" />
          <span className="u-marginLeftExtraSmall">Thông tin cá nhân</span>
        </Dropdown.Item>
        <Dropdown.Item
          className="u-paddingVerticalExtraSmall u-cursorPointer"
          onClick={onChangePasswordMenuClick}
        >
          <Icon name="lock" size="small" className="u-textGray" />
          <span className="u-marginLeftExtraSmall">Đổi mật khẩu</span>
        </Dropdown.Item>
        <Dropdown.Item
          className="u-paddingVerticalExtraSmall u-cursorPointer"
          onClick={onLogOutClick}
        >
          <Icon name="power" size="small" className="u-textGray" />
          <span className="u-marginLeftExtraSmall">Đăng xuất</span>
        </Dropdown.Item>
      </Dropdown.Container>
    </Dropdown>
  );
}
