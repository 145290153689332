import { UserAction } from 'constants/actions';
import { logOut } from 'store/actions/user.action';
import Auth from 'utils/auth';
import { getSuccessAction, isFailureAction, getOriginalAction } from 'utils/redux';

export default ({ dispatch }) => next => (action) => {
  switch (action.type) {
    case getSuccessAction(UserAction.LOG_IN_WITH_PASSWORD): {
      Auth.setAuth({
        accessToken: action.payload?.accessToken,
      });
      break;
    }

    default:
      break;
  }

  const res = next(action);

  // Auto log out if received Unauthorized error
  if (
    isFailureAction(action.type)
    && getOriginalAction(action.type) !== UserAction.LOG_OUT
    && action.payload.data?.status === 401
    && !!Auth.getAuthProperty()
  ) {
    dispatch(logOut());
  }

  switch (action.type) {
    case UserAction.LOG_OUT: {
      Auth.logout();
      break;
    }
    default:
      break;
  }

  return res;
};
