import React from 'react';
import { Form, FileAttachment, SafeAnchor } from '@ahaui/react';
import { useDispatch } from 'react-redux';
import { FileMetadata, ModalKey } from 'constants/common';
import { showModal } from 'store/actions/modal.action';

export default function FormFileGroup({
  id,
  register,
  watch,
  errors,
  label,
  name,
  required,
  registerProps,
  formGroupProps,
  formLabelProps,
  formContextProps,
  formInputWrapperProps,
  feedback,
  renderFeedback,
  ...formInputProps
}) {
  const dispatch = useDispatch();
  const registered = register?.(name, {
    required,
    ...registerProps,
  });

  const files = watch?.(name) || [];

  const renderCurrentFiles = () => Array.from(files)?.map?.(file => {
    if (file.type === FileMetadata.PDF.MIME_TYPE) {
      return (
        <div
          className="u-inlineBlock u-marginBottomSmall u-marginRightSmall"
          style={{ maxWidth: 300 }}
        >
          <FileAttachment
            fileType="text"
            fileName={file.name}
            fileTypeLabel="PDF"
            closeButton={false}
            actionLeft={() => (
              <SafeAnchor
                className="hover:u-textDecorationNone u-block u-flexGrow1 u-textCenter u-paddingTiny"
                onClick={() => dispatch(showModal(ModalKey.GOOGLE_DOCS_VIEWER, {
                  fileUrl: 'url',
                }))}
              >
                Xem
              </SafeAnchor>
            )}
            actionRight={() => (
              <SafeAnchor
                className="hover:u-textDecorationNone u-block u-flexGrow1 u-textCenter u-paddingTiny"
                href="url"
                target="_blank"
              >
                Tải về
              </SafeAnchor>
            )}
          />
        </div>
      );
    }
    return null;
  });

  return (
    <>
      <Form.Group
        controlId={id}
        requiredControl={required}
        {...formGroupProps}
      >
        {label && (
          <Form.Label
            {...formLabelProps}
          >
            {label}
          </Form.Label>
        )}
        <div
          className="u-flexGrow1"
          {...formInputWrapperProps}
        >
          <Form.File
            name={name}
            isInvalid={!!errors?.[name]}
            fileName={Array.from(files)?.map?.(file => file.name).join(', ')}
            browseText="Chọn file"
            {...formInputProps}
            {...registered}
          />
          {errors?.[name] && (
            <Form.Feedback type="invalid">
              {errors[name].message}
            </Form.Feedback>
          )}
          {renderFeedback && feedback && renderFeedback(feedback)}
        </div>
      </Form.Group>
      {files?.length > 0 && (
        <div
          {...formContextProps}
        >
          {renderCurrentFiles()}
        </div>
      )}
    </>
  );
}
