import React from 'react';
import {
  TopMenu,
} from '@ahaui/react';
import { useLocation, useHistory } from 'react-router-dom';

export default function NavBar() {
  const { pathname } = useLocation();
  const history = useHistory();
  const keysChain = pathname.substring(1, pathname.length).split('/').join('.');

  const onItemClick = (keysChain) => {
    const path = keysChain.split('.').join('/');
    history.push(`/${path}`);
  };

  return (
    <nav className="u-borderBottom u-backgroundUltraLight">
      <div className="u-paddingHorizontalSmall u-paddingVerticalExtraTiny">
        <TopMenu
          current={keysChain}
          onSelect={onItemClick}
          className="u-backgroundTransparent"
        >
          <TopMenu.SubMenu eventKey="projects" title="ĐỒ ÁN" className="u-shadowMedium">
            <TopMenu.Item eventKey="project-list">Danh sách đồ án</TopMenu.Item>
            <TopMenu.Item eventKey="project-registration">Đăng ký nguyện vọng</TopMenu.Item>
            <TopMenu.Item eventKey="project-references">Đồ án tham khảo</TopMenu.Item>
            <TopMenu.Item eventKey="project-orientations">Định hướng đề tài</TopMenu.Item>
            <TopMenu.Item eventKey="company-list">DS doanh nghiệp</TopMenu.Item>
            <TopMenu.Item eventKey="project-plagiarism">Kiểm tra trùng lặp</TopMenu.Item>
          </TopMenu.SubMenu>
          <TopMenu.Item eventKey="time-table">LỊCH HỌC</TopMenu.Item>
          <TopMenu.Item eventKey="online-form">BIỂU MẪU</TopMenu.Item>
          <TopMenu.Item eventKey="user-info">CÁ NHÂN</TopMenu.Item>
          <TopMenu.Item eventKey="faq">HỎI ĐÁP</TopMenu.Item>
        </TopMenu>
      </div>
    </nav>
  );
}
