import React from 'react';
import { SafeAnchor } from '@ahaui/react';
import formatter from 'utils/formatter';

export default function ExternalLink({
  href,
  children,
  ...rest
}) {
  const externalHref = formatter.getExternalHref(href);

  return (
    <SafeAnchor
      href={externalHref}
      target="_blank"
      {...rest}
    >
      {children}
    </SafeAnchor>
  );
}
