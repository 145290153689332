import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import moment from 'moment';
import { Plugins } from '@ahaui/react';

import '@ahaui/css/dist/index.min.css';
import 'assets/css/custom.css';

import store from 'store';
import {
  LogoAssetsPlugin,
  EmptyStateAssetsPlugin,
} from 'utils/ahaPlugins';
import App from 'components/App';

moment.locale('en', {
  week: {
    // Monday is the first day of the week.
    dow: 1,
  },
});

Plugins.loadPlugin(LogoAssetsPlugin);
Plugins.loadPlugin(EmptyStateAssetsPlugin);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter
      basename={process.env.PUBLIC_URL}
    >
      <ReduxProvider store={store}>
        <App />
      </ReduxProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
