import React from 'react';

export default function withPropsWaiting(WrappedComponent, propsChecker) {
  function PropsWaitingWrapper(props) {
    const isPropsReady = propsChecker(props);

    if (!isPropsReady) return null;

    return (
      <WrappedComponent {...props} />
    );
  }

  return PropsWaitingWrapper;
}
