import React from 'react';
import classNames from 'classnames';
import { Skeleton } from '@ahaui/react';
import Image from 'components/Common/Image';
import ExternalLink from 'components/Common/ExternalLink';

export default function AdsCard({
  isSkeleton = false,
  ads,
  className,
  ...rest
}) {
  // TODO: track ads view, ads click
  return (
    <ExternalLink
      href={!isSkeleton && ads?.url}
      className={classNames(
        'u-block u-backgroundWhite u-border u-roundedLarge u-shadowLarge u-overflowHidden u-marginBottomSmall u-lineHeightNone',
        className,
      )}
      {...rest}
    >
      <Image
        className="u-widthFull u-borderBottom"
        isSkeleton={isSkeleton}
        src={ads?.image}
        alt={ads?.name}
      />
      <div className="u-paddingExtraSmall u-text100">
        <div className="u-fontMedium u-textPrimary">
          {!isSkeleton ? ads?.unitName : <Skeleton style={{ marginBottom: 4, height: 14, width: '40%' }} />}
        </div>
        <span className="u-textDark">
          {!isSkeleton ? ads?.name : <Skeleton />}
        </span>
      </div>
    </ExternalLink>
  );
}
