import { ProjectAction } from 'constants/actions';
import { getSuccessAction } from 'utils/redux';

const getInitialState = () => ({
  projects: null,
  projectRequests: null,
  projectTopicsSchool: null,
  projectTopicsCompany: null,
  plagiarismRequests: null,
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case getSuccessAction(ProjectAction.GET_PROJECTS): {
      return {
        ...state,
        projects: action.payload || [],
      };
    }

    case getSuccessAction(ProjectAction.GET_PROJECT_REQUESTS): {
      const data = action.payload || [];
      const projectRequests = data.map((request) => {
        let finalOrder = null;
        if (request.finalTeacherX) {
          if (request.finalTeacherX.id === request.teacher1X.id) finalOrder = 1;
          if (request.finalTeacherX.id === request.teacher2X.id) finalOrder = 2;
          if (request.finalTeacherX.id === request.teacher3X.id) finalOrder = 3;
        } else if (request.finalCompanyX) {
          if (request.finalCompanyX.id === request.company1X.id) finalOrder = 1;
          if (request.finalCompanyX.id === request.company2X.id) finalOrder = 2;
          if (request.finalCompanyX.id === request.company3X.id) finalOrder = 3;
        }
        return {
          ...request,
          finalOrder,
        };
      });

      return {
        ...state,
        projectRequests,
      };
    }

    case getSuccessAction(ProjectAction.GET_PROJECT_TOPICS_SCHOOL): {
      return {
        ...state,
        projectTopicsSchool: action.payload || [],
      };
    }

    case getSuccessAction(ProjectAction.GET_PROJECT_TOPICS_COMPANY): {
      return {
        ...state,
        projectTopicsCompany: action.payload || [],
      };
    }

    case getSuccessAction(ProjectAction.GET_PROJECT_PLAGIARISM_REQUESTS): {
      return {
        ...state,
        plagiarismRequests: action.payload || [],
      };
    }

    default:
      break;
  }
  return state;
};
