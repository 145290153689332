import React from 'react';
import { useSelector } from 'react-redux';
import UserInfoEditor from './UserInfoEditor';

export default function UserInfo() {
  const userInfo = useSelector(state => state.user.info);

  return (
    <UserInfoEditor userInfo={userInfo} />
  );
}
