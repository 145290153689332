import React from 'react';
import { SafeAnchor } from '@ahaui/react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { ModalKey, KeyboardKey } from 'constants/common';
import { ErrorMessage } from 'constants/message';
import { showModal } from 'store/actions/modal.action';
import { logInWithPassword } from 'store/actions/user.action';
import FormGroup from 'components/Common/Form/FormGroup';

import ModalBase from './ModalBase';

export default function LogInModal({
  onModalClose,
}) {
  const dispatch = useDispatch();
  const formHookInfo = useForm({
    criteriaMode: 'all',
  });

  const {
    handleSubmit,
    formState: {
      isSubmitting,
      errors,
    },
    getValues,
  } = formHookInfo;

  const onSubmit = handleSubmit(async (data) => {
    const { success } = await dispatch(logInWithPassword({
      username: data.username,
      password: data.password,
    }));
    if (success) {
      onModalClose();
    }
  });

  const onKeyDown = (e) => e.key === KeyboardKey.ENTER && onSubmit();

  const onForgotPasswordClick = () => {
    dispatch(showModal(ModalKey.FORGOT_PASSWORD, {
      prefilledUsername: getValues('username'),
    }));
  };

  return (
    <ModalBase
      headerText="Đăng nhập"
      size="medium"
      closable={!isSubmitting}
      onHide={onModalClose}
      onKeyDown={onKeyDown}
      body={(
        <>
          <FormGroup
            id="bkeu-login-username"
            formHookInfo={formHookInfo}
            label="Tên đăng nhập"
            name="username"
            type="text"
            required={ErrorMessage.REQUIRED_FIELD}
            disabled={isSubmitting}
            placeholder="Thường là MSSV"
            maxLength={100}
          />
          <FormGroup
            id="bkeu-login-password"
            formHookInfo={formHookInfo}
            label="Mật khẩu"
            name="password"
            type="password"
            required={ErrorMessage.REQUIRED_FIELD}
            disabled={isSubmitting}
            placeholder="Điền mật khẩu"
            maxLength={100}
          />
          <SafeAnchor
            className="u-text200 u-textAccent hover:u-textAccentDark"
            disabled={isSubmitting}
            onClick={onForgotPasswordClick}
          >
            Bạn quên mật khẩu?
          </SafeAnchor>
        </>
      )}
      footerType="single"
      primaryButtonText="ĐĂNG NHẬP"
      onClickPrimaryButton={onSubmit}
      disablePrimaryButton={isSubmitting || (Object.keys(errors).length) > 0}
    />
  );
}
