import React, { useEffect, useState } from 'react';
import { SafeAnchor } from '@ahaui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getVisibleAds } from 'store/actions/user.action';
import { withCache } from 'store/actions/hoc.action';

import AdsCard from './AdsCard';

// DO NOT show ads pannel at home page
const BlackListPathname = [
  '/',
];

export default function AdsSidebar() {
  const dispatch = useDispatch();
  const allAds = useSelector(state => state.user.ads);
  const { pathname } = useLocation();
  const [show, setShow] = useState(true);

  const shouldHideAds = !show
    || (allAds && allAds.length === 0)
    || BlackListPathname.includes(pathname);

  const cardProps = {
    className: 'u-marginLeftSmall lg:u-marginLeftNone',
    style: {
      minWidth: 280,
    },
  };

  useEffect(() => {
    dispatch(withCache(getVisibleAds()));
  }, [dispatch]);

  if (shouldHideAds) return null;

  return (
    <div
      className="u-flexShrink0 u-overflowHidden"
      style={{
        width: 300,
      }}
    >
      <div className="u-paddingVerticalLarge u-paddingRightSmall u-flex u-flexColumn">
        {!allAds && (
          <>
            <AdsCard isSkeleton {...cardProps} />
            <AdsCard isSkeleton {...cardProps} />
          </>
        )}
        {allAds?.map(ads => (
          <AdsCard
            key={ads.id}
            ads={ads}
            {...cardProps}
          />
        ))}
        {show && (
          <SafeAnchor
            className="u-text200 u-textLight u-alignSelfEnd"
            onClick={() => setShow(false)}
          >
            Tạm ẩn tin tức
          </SafeAnchor>
        )}
      </div>
    </div>
  );
}
