import { CompanyAction } from 'constants/actions';
import { get } from 'utils/request';

export const getCompany = (companyId) => ({
  type: CompanyAction.GET_COMPANY,
  promise: () => get(`/companies/${companyId}`),
});

export const getCompanies = () => ({
  type: CompanyAction.GET_COMPANIES,
  promise: () => get('/companies'),
});

export const getCompanyFeedbacks = (companyId) => ({
  type: CompanyAction.GET_COMPANY_FEEDBACK,
  promise: () => get(`/companies/${companyId}/feedbacks`),
  payload: {
    companyId,
  },
});

export const getCompanyAds = (companyId) => ({
  type: CompanyAction.GET_COMPANY_ADS,
  promise: () => get(`/companies/${companyId}/ads`),
  payload: {
    companyId,
  },
});
