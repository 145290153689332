import React, { useEffect, useState } from 'react';
import { Tooltip, Icon } from '@ahaui/react';

export default React.forwardRef(({
  copiedNonce,
  ...props
}, ref) => {
  const [copied, setCopied] = useState(false);
  const [initialCopiedNonce] = useState(copiedNonce);

  useEffect(() => {
    if (copiedNonce !== initialCopiedNonce) {
      setCopied(true);
    }
  }, [copiedNonce, initialCopiedNonce]);

  return (
    <Tooltip
      ref={ref}
      id={`copy-tooltip-${copiedNonce}`}
      {...props}
    >
      {copied ? (
        <div className="u-flex u-alignItemsCenter">
          <span>Đã sao chép</span>
          <span
            className="u-textPositive u-lineHeightNone u-marginLeftTiny"
          >
            <Icon
              name="checkmark"
              size="extraSmall"
            />
          </span>
        </div>
      ) : (
        <span>Sao chép</span>
      )}
    </Tooltip>
  );
});
