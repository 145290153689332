import QueryString from 'query-string';
import configs from 'configs';
import Auth from 'utils/auth';

export class FetchError extends Error {
  constructor(data) {
    super();
    this.name = 'FetchError';
    this.data = data;
    this.stack = (new Error()).stack;
  }
}

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const constructUrl = (endpoint, params, override = true) => {
  const { url, query } = QueryString.parseUrl(endpoint);

  let newQuery;
  if (override) {
    newQuery = {
      ...query,
      ...params,
    };
  } else {
    newQuery = {
      ...params,
      ...query,
    };
  }
  return QueryString.stringifyUrl({
    url,
    query: newQuery,
  }, {
    encode: true,
  });
};

const request = async (_endpoint, method, body, customHeaders = {}, withAuth = true) => {
  let endpoint = _endpoint;
  // If not external domain, add API_URL to endpoint
  if (!_endpoint.startsWith('http')) {
    endpoint = configs.apiUrl + endpoint;
  }
  const headers = {
    ...defaultHeaders,
    ...customHeaders,
  };
  if (withAuth) {
    const accessToken = Auth.getAccessToken();

    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }
  }
  let data = null;
  if (body) {
    if (headers['Content-Type'] === 'application/json') {
      data = JSON.stringify(body);
    } else {
      delete headers['Content-Type'];
      data = body;
    }
  } else {
    delete headers['Content-Type'];
  }
  const fetchOpts = {
    method,
    headers,
  };
  if (method !== 'HEAD' && method !== 'GET') {
    fetchOpts.body = data;
  }
  const response = await fetch(endpoint, fetchOpts);

  let json;
  try {
    json = await response.json();
  } catch (jsonParseError) {
    throw new FetchError({
      code: response.status,
      message: 'Internal Server Error: response is not in JSON format.',
    });
  }

  if (response.status < 200 || response.status >= 300) {
    const errorData = {
      status: response.status,
      errorMessage: response.statusText,
      ...json,
    };
    throw new FetchError(errorData);
  }

  return json;
};

export const get = (endpoint, params, ...rest) => {
  const url = constructUrl(endpoint, params);
  return request(url, 'GET', ...rest);
};

export const post = (endpoint, body, ...rest) => (
  request(endpoint, 'POST', body, ...rest)
);

export const put = (endpoint, body, ...rest) => (
  request(endpoint, 'PUT', body, ...rest)
);

export const del = (endpoint, body, ...rest) => (
  request(endpoint, 'DELETE', body, ...rest)
);

export const upload = (endpoint, file, customHeaders, ...rest) => {
  const formData = new FormData();
  formData.append('file', file, file.name);
  return post(
    endpoint,
    formData,
    {
      'Content-Type': 'multipart/form-data',
      ...customHeaders,
    },
    ...rest,
  );
};

export default {
  get,
  post,
  put,
  del,
  upload,
};
