import { ProjectAction, CompanyAction } from 'constants/actions';
import { getCompany } from 'store/actions/company.action';
import { cacheRequests } from 'store/actions/app.action';
import { withCache } from 'store/actions/hoc.action';
import { getSuccessAction } from 'utils/redux';

export default ({ dispatch, getState }) => next => (action) => {
  const res = next(action);

  switch (action.type) {
    case getSuccessAction(ProjectAction.GET_PROJECTS): {
      const {
        project: {
          projects,
        },
      } = getState();
      projects.forEach((project) => {
        if (project.companyId > 0) {
          dispatch(
            withCache(
              getCompany(project.companyId),
              project.companyId,
            ),
          );
        }
      });

      break;
    }

    case getSuccessAction(CompanyAction.GET_COMPANIES): {
      const {
        company: {
          companies,
        },
      } = getState();
      const loadedCompaniesId = Object.keys(companies);
      const actionKeys = loadedCompaniesId.map(companyId => `${CompanyAction.GET_COMPANY}-${companyId}`);
      dispatch(cacheRequests(actionKeys));

      break;
    }

    default:
      break;
  }

  return res;
};
