import React from 'react';
import DOMPurify from 'dompurify';
import classNames from 'classnames';
import { EmptyState } from '@ahaui/react';
import formatter from 'utils/formatter';
import EmptyRows from 'components/Common/EmptyRows';
import SeeMoreWrapper from 'components/Common/SeeMoreWrapper';

export default function FeedbackSection({
  feedbacksInfo,
}) {
  const renderFeedbacks = () => {
    const numberOfCols = 11;
    const numberOfRows = 3;

    if (!feedbacksInfo) {
      return (
        <EmptyRows
          numberOfCols={numberOfCols}
          numberOfRows={numberOfRows}
        />
      );
    }
    if (feedbacksInfo.length === 0) {
      return (
        <tr>
          <td colSpan={numberOfCols} className="u-backgroundWhite">
            <EmptyState name="general" className="u-widthFull">
              <EmptyState.Description>
                Không có đánh giá
              </EmptyState.Description>
            </EmptyState>
          </td>
        </tr>
      );
    }
    return [...feedbacksInfo.map((feedback, index) => (
      <tr key={feedback.id}>
        <td style={{ width: '1%' }}>{index + 1}</td>
        <td>N/A</td>
        <td>N/A</td>
        <td>
          <SeeMoreWrapper>
            <div
              className="all-imageMaxWidthFull"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(feedback.comment) }}
            />
          </SeeMoreWrapper>
        </td>
        <td>
          <span
            className={classNames(
              'u-fontMedium',
              formatter.getRatingColorClassName(feedback.roundedAvgRateValue),
            )}
          >
            {feedback.roundedAvgRateValue}
          </span>
        </td>
        {feedback.questions?.map((question) => (
          <td key={question.index}>{question.finalAnswer}</td>
        ))}
      </tr>
    ))];
  };
  return (
    <div className="u-marginVerticalExtraSmall u-borderLeft u-borderRight u-borderBottom u-overflowHorizontalAuto">
      <table className="Table Table--striped Table--hoverable u-backgroundWhite u-textDark all-text200">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col" style={{ minWidth: 50 }}>Đề tài</th>
            <th scope="col" style={{ minWidth: 50 }}>Học kỳ</th>
            <th scope="col" style={{ minWidth: 400 }}>Comment</th>
            <th scope="col" style={{ minWidth: 96 }}>Trung bình đánh giá (5/5)</th>
            <th scope="col" style={{ minWidth: 68 }}>Về Công nghệ</th>
            <th scope="col" style={{ minWidth: 132 }}>Môi trường làm việc (tính chuyên nghiệp)</th>
            <th scope="col" style={{ minWidth: 103 }}>Độ tận tình hướng dẫn</th>
            <th scope="col" style={{ minWidth: 96 }}>Vị trí Công ty (location)</th>
            <th scope="col" style={{ minWidth: 77 }}>Mức độ ưu đãi</th>
            <th scope="col" style={{ minWidth: 180 }}>Overall (Bạn có mong muốn tiếp tục làm việc ở Công ty không?)</th>
          </tr>
        </thead>
        <tbody>
          {renderFeedbacks()}
        </tbody>
      </table>
    </div>
  );
}
