import React from 'react';
import { Separator, EmptyState } from '@ahaui/react';
import { UserStatus } from 'constants/common';
import {
  getCompanyTypeName,
  getProductTypeName,
  getCompanyRoleName,
  getStaffStatusName,
  getInternReadyMessage,
} from 'utils/message';
import EmptyRows from 'components/Common/EmptyRows';
import ExternalLink from 'components/Common/ExternalLink';

export default function InfoSection({
  companyInfo,
}) {
  const {
    name,
    fullName,
    website,
    address,
    companyType,
    productTypes,
    foundedYear,
    staffNum,
    capital,
    internReady,
    internNum,
    avatar,
    userInfos,
  } = companyInfo;

  const renderContactInfos = () => {
    const numberOfCols = 6;
    const numberOfRows = 3;

    if (!userInfos) {
      return (
        <EmptyRows
          numberOfCols={numberOfCols}
          numberOfRows={numberOfRows}
        />
      );
    }
    if (userInfos.length === 0) {
      return (
        <tr>
          <td colSpan={numberOfCols} className="u-backgroundWhite">
            <EmptyState name="general" className="u-widthFull">
              <EmptyState.Description>
                Không có liên hệ
              </EmptyState.Description>
            </EmptyState>
          </td>
        </tr>
      );
    }
    return userInfos
      .filter((user) => user.status !== UserStatus.USER_STATUS_QUIT)
      .map((user) => (
        <tr key={user.id}>
          <td className="u-textNoWrap">{user.fullName}</td>
          <td>{user.email}</td>
          <td>{user.phoneNumber}</td>
          <td>{user.position}</td>
          <td>{getCompanyRoleName(user.roleId)}</td>
          <td>{getStaffStatusName(user.status)}</td>
        </tr>
      ));
  };

  return (
    <div className="u-paddingTopExtraSmall">
      <div className="u-text400 u-fontMedium u-marginBottomSmall">Thông tin Công ty</div>
      <div className="Grid Grid--smallGutter">
        <div className="u-sizeFull md:u-size1of2">
          <div className="u-marginBottomSmall">
            <div className="u-textGray">Tên công ty:</div>
            <div className="u-fontMedium">{name}</div>
          </div>
          <div className="u-marginBottomSmall">
            <div className="u-textGray">Tên Công ty đầy đủ:</div>
            <div className="u-fontMedium">{fullName}</div>
          </div>
          <div className="u-marginBottomSmall">
            <div className="u-textGray">Website:</div>
            <div className="u-fontMedium">
              <ExternalLink href={website}>
                {website}
              </ExternalLink>
            </div>
          </div>
          <div className="u-marginBottomSmall">
            <div className="u-textGray">Địa chỉ:</div>
            <div className="u-fontMedium">{address}</div>
          </div>
          <div className="u-marginBottomSmall">
            <div className="u-textGray">Loại Doanh nghiệp:</div>
            <div className="u-fontMedium">{getCompanyTypeName(companyType)}</div>
          </div>
          <div className="u-marginBottomSmall">
            <div className="u-textGray">Lĩnh vực hoạt động:</div>
            {productTypes?.map(type => (
              <div
                key={type}
                className="u-fontMedium"
              >
                {getProductTypeName(type)}
              </div>
            ))}
          </div>
        </div>
        <div className="u-sizeFull md:u-size1of2">
          <div className="u-marginBottomSmall">
            <div className="u-textGray">Năm thành lập:</div>
            <div className="u-fontMedium">{foundedYear}</div>
          </div>
          <div className="u-marginBottomSmall">
            <div className="u-textGray">Số Nhân viên:</div>
            <div className="u-fontMedium">{staffNum}</div>
          </div>
          <div className="u-marginBottomSmall">
            <div className="u-textGray">Vốn điều lệ (triệu đồng):</div>
            <div className="u-fontMedium">{capital}</div>
          </div>
          <div className="u-marginBottomSmall">
            <div className="u-textGray">Tiếp nhận SV thực tập?</div>
            <div className="u-fontMedium">{getInternReadyMessage(internReady)}</div>
          </div>
          <div className="u-marginBottomSmall">
            <div className="u-textGray">Số thực tập mong muốn nhận:</div>
            <div className="u-fontMedium">{internNum}</div>
          </div>
          <div className="u-marginBottomSmall">
            <div className="u-textGray">Logo của Doanh nghiệp:</div>
            <img
              className="u-maxWidthFull"
              style={{ maxHeight: 150 }}
              src={avatar}
              alt=""
            />
          </div>
        </div>
      </div>
      <Separator
        variant="lighter"
        lineType="dashed"
        className="u-marginBottomSmall"
      />
      <div className="u-text400 u-fontMedium u-marginBottomSmall">Các thông tin Tài khoản liên hệ</div>
      <div className="u-marginVerticalExtraSmall u-borderLeft u-borderRight u-borderBottom u-overflowHorizontalAuto">
        <table className="Table Table--striped Table--hoverable u-backgroundWhite u-textDark u-text200">
          <thead className="u-textNoWrap">
            <tr>
              <th scope="col">Họ và tên</th>
              <th scope="col">Email</th>
              <th scope="col">SĐT</th>
              <th scope="col">Vị trí</th>
              <th scope="col">Vai trò</th>
              <th scope="col">Trạng thái</th>
            </tr>
          </thead>
          <tbody>
            {renderContactInfos()}
          </tbody>
        </table>
      </div>

      {/* TODO: add company feedback section */}
    </div>
  );
}
