import Storage from 'utils/storage';
import { StorageKey } from 'constants/common';

class AuthClass {
  constructor(storage, authKey) {
    if (!storage || !authKey) {
      throw new Error('Missing storage or authKey');
    }
    this.storage = storage;
    this.authKey = authKey;
    this.data = this.storage.getJson(this.authKey);
    this.logOutListeners = [];
    window.addEventListener('storage', (e) => {
      if (
        this.storage.getStorageKey(this.authKey) === e.key
        && !!e.oldValue
        && !e.newValue
        && this.logOutListeners.length > 0
      ) {
        this.logOutListeners.forEach((callback) => {
          if (typeof callback === 'function') {
            callback(e);
          }
        });
      }
    });
  }

  isAuth() {
    return !!(this.getAuthProperty());
  }

  getAccessToken() {
    return this.data?.accessToken;
  }

  getAuthProperty() {
    // If we call directly to App Engine, use sessionId instead of accessToken
    return this.data?.accessToken;
  }

  setAuth(data) {
    this.data = data;
    this.storage.setJson(this.authKey, data);
  }

  logout() {
    this.data = {};
    this.storage.removeItem(this.authKey);
  }

  addLogOutListener =(callback) => {
    this.logOutListeners = [
      ...this.logOutListeners,
      callback,
    ];
  }

  removeLogOutListener = (callback) => {
    this.logOutListeners = this.logOutListeners.filter(item => item !== callback);
  }

  removeAllLogOutListener() {
    this.logOutListeners = [];
  }
}

const Auth = new AuthClass(Storage, StorageKey.AUTHENTICATION);

export default Auth;
