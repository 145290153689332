import React, { useMemo } from 'react';
import isEmail from 'validator/es/lib/isEmail';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Message, Separator, Button } from '@ahaui/react';
import configs from 'configs';
import { ErrorMessage } from 'constants/message';
import { StorageKey } from 'constants/common';
import { uploadFile } from 'store/actions/file.action';
import { createProjectPlagiarismRequests } from 'store/actions/project.action';
import { withBlock } from 'store/actions/hoc.action';
import toast from 'utils/toast';
import Storage from 'utils/storage';
import FormGroup from 'components/Common/Form/FormGroup';
import withReduxStateWaiting from 'components/HOCs/withReduxStateWaiting';

const FormFields = [
  {
    name: 'languages',
    label: 'Chọn chức năng',
    type: 'select',
    required: ErrorMessage.REQUIRED_FIELD,
    options: ['Kiểm tra trùng lặp Việt-Việt'],
    disabled: true,
  },
  {
    name: 'file',
    label: 'Tài liệu cần kiểm tra',
    type: 'file',
    required: ErrorMessage.REQUIRED_FIELD,
    feedback: {
      type: 'info',
      value: '* Nội dung file cần kiểm tra là văn bản tiếng Việt có dấu, file có đuôi là pdf.',
    },
  },
  {
    name: 'emails',
    label: 'Email nhận kết quả',
    type: 'tagInput',
    variant: 'positive_subtle',
    inputProps: {
      placeholder: 'Nhập từng email và nhấn ENTER',
      style: { width: '100%' },
    },
    required: ErrorMessage.REQUIRED_FIELD,
    tagValidator: isEmail,
  },
];

const dataWaitingSelector = (state) => !!state.user.info;

function UploadTab({
  handleSwitchTab,
}) {
  const dispatch = useDispatch();
  const allEmails = useSelector(state => state.user.info?.allEmails);
  const formHookInfo = useForm({
    criteriaMode: 'all',
    defaultValues: {
      languages: 'Kiểm tra trùng lặp Việt-Việt',
      emails: [...allEmails],
    },
  });
  const availableToCheck = useMemo(() => {
    const lastCheck = parseInt(Storage.getItem(StorageKey.LAST_PLAGIARISM_CHECK) ?? 0, 10);
    const now = Date.now();

    // If more than 5 mins passed since the last check, enable the button
    if ((now - lastCheck) > configs.plagiarismCheckLimit) {
      return true;
    }
    return false;
  }, []);

  const {
    handleSubmit,
    formState: {
      isSubmitting,
    },
  } = formHookInfo;

  const onSubmit = handleSubmit(async (data) => {
    const thesisFile = data.file?.[0];
    if (!thesisFile) return;

    const res1 = await dispatch(withBlock(uploadFile(thesisFile)));

    if (!res1.success || !res1.result) return;

    const res2 = await dispatch(withBlock(createProjectPlagiarismRequests(res1.result)));

    if (!res2.success) return;

    toast.success(res2.result ?? 'Hệ thống đang kiểm tra mức độ trùng lặp. Vui lòng đợi trong ít phút.');

    Storage.setItem(StorageKey.LAST_PLAGIARISM_CHECK, Date.now());

    handleSwitchTab();
  });

  const renderRow = ({
    name,
    ...formInputProps
  }) => (
    <FormGroup
      id={`bkeu-plagiarism-${name}`}
      formHookInfo={formHookInfo}
      name={name}
      {...formInputProps}
      formGroupProps={{
        className: 'u-sizeFull u-flexWrap',
        style: {
          display: 'flex',
        },
      }}
      formLabelProps={{
        className: 'lg:u-paddingVerticalTiny u-sizeFull lg:u-size4of12 lg:u-textRight lg:u-paddingRightExtraSmall u-flexShrink0',
      }}
      formContextProps={{
        className: 'u-sizeFull lg:u-offset4of12 lg:u-size8of12',
      }}
      formInputWrapperProps={{
        className: 'u-flexGrow1',
      }}
      className="u-flexGrow1"
    />
  );

  return (
    <div>
      <div className="u-marginBottomSmall">
        <Message variant="warning">
          <Message.Container>
            <Message.Title>
              Lưu ý
            </Message.Title>
            <Message.Content>
              Để tránh quá tải hệ thống, bạn hãy chờ
              <strong> 5 phút </strong>
              trước khi kiểm tra tài liệu mới. Xin cám ơn!
            </Message.Content>
          </Message.Container>
        </Message>
      </div>
      <div className="Grid Grid--smallGutter">
        {FormFields.map(field => renderRow(field))}
        <div className="u-sizeFull u-flex u-justifyContentEnd">
          <Button
            onClick={onSubmit}
            disabled={isSubmitting || !availableToCheck}
          >
            Kiểm tra
          </Button>
        </div>
        {!availableToCheck && (
          <div className="u-sizeFull u-flex u-justifyContentEnd u-text200 u-textNegative u-fontItalic">
            * Vui lòng đợi 5 phút giữa các lần kiểm tra để tránh gây quá tải hệ thống
          </div>
        )}
      </div>
      <Separator variant="lighter" className="u-marginVerticalSmall" />
      <div>
        <div className="u-text100">COOPY được phát triển bởi Trường Đại học Bách khoa Hà Nội với hai tính năng chính là kiểm trùng văn bản Việt – Việt và Việt - Anh.</div>
        <div className="u-text100">Kho dữ liệu của hệ thống gồm hơn 2.000.000 tài liệu tiếng Việt và tiếng Anh thuộc nhiều chuyên ngành trên Internet, các bài báo, đồ án, khóa luận tốt nghiệp, luận văn, luận án của các trường đại học. Kho dữ liệu của hệ thống liên tục được cập nhật từ nhiều nguồn trên mạng và các đơn vị hợp tác.</div>
      </div>
    </div>
  );
}

export default withReduxStateWaiting(UploadTab, dataWaitingSelector);
