import React, { useMemo } from 'react';
import { Form } from '@ahaui/react';

export default function FormSelectGroup({
  id,
  register,
  errors,
  label,
  name,
  required,
  registerProps,
  formGroupProps,
  formLabelProps,
  formInputWrapperProps,
  options,
  feedback,
  renderFeedback,
  ...formInputProps
}) {
  const registered = register?.(name, {
    required,
    ...registerProps,
  });

  const formattedOptions = useMemo(() => {
    if (!(options?.length > 0)) {
      return options ?? [];
    }
    if (options[0]?.value === undefined) {
      return options.map((option) => ({
        name: option,
        value: option,
      }));
    }
    return options;
  }, [options]);

  return (
    <Form.Group
      controlId={id}
      requiredControl={required}
      {...formGroupProps}
    >
      {label && (
        <Form.Label
          {...formLabelProps}
        >
          {label}
        </Form.Label>
      )}
      <div
        className="u-flexGrow1"
        {...formInputWrapperProps}
      >
        <Form.Select
          name={name}
          isInvalid={!!errors?.[name]}
          {...formInputProps}
          {...registered}
        >
          {formattedOptions?.map(({
            name,
            value,
            ...rest
          }) => (
            <option
              key={value}
              value={value}
              {...rest}
            >
              {name ?? value}
            </option>
          ))}
        </Form.Select>
        {errors?.[name] && (
        <Form.Feedback type="invalid">
          {errors[name].message}
        </Form.Feedback>
        )}
        {renderFeedback && feedback && renderFeedback(feedback)}
      </div>
    </Form.Group>
  );
}
