import { useState, useCallback } from 'react';
import formatter from 'utils/formatter';

export default function usePagination({
  items,
  defaultPageOffset = 0,
  defaultItemsPerPage = 50,
  disabled = false,
}) {
  const [pageOffset, setPageOffset] = useState(defaultPageOffset);
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);

  const totalItems = items?.length || 0;
  const firstVisibleRow = formatter.forceInRange(pageOffset + 1, 0, totalItems);
  const lastVisibleRow = formatter.forceInRange(firstVisibleRow + itemsPerPage - 1, 0, totalItems);

  const paginatedItems = disabled
    ? items
    : items?.slice(firstVisibleRow - 1, lastVisibleRow);

  const onItemsPerPageChange = useCallback((ipp) => {
    const num = parseInt(ipp, 10);
    setItemsPerPage(num);
  }, []);

  const onPageOffsetChange = setPageOffset;

  return {
    paginatedItems,
    totalItems,
    pageOffset,
    onPageOffsetChange,
    itemsPerPage,
    onItemsPerPageChange,
    firstVisibleRow,
    lastVisibleRow,
  };
}
