import { cacheRequest } from 'store/actions/app.action';
import { isSuccessAction, getOriginalAction } from 'utils/redux';

export default ({ dispatch }) => next => (action) => {
  const {
    shouldCache,
    cacheMetadata,
    type,
  } = action;

  const cacheKey = cacheMetadata?.cacheKey || getOriginalAction(type);

  if (isSuccessAction(type) && shouldCache) {
    dispatch(cacheRequest(cacheKey));
  }

  return next(action);
};
