import React from 'react';

import ModalBase from './ModalBase';

export default function GoogleDocsViewerModal({
  onModalClose,
  fileUrl,
}) {
  return (
    <ModalBase
      headerText="Xem file"
      size="medium"
      closable
      onHide={onModalClose}
      body={(
        <iframe
          title="docs-viewer"
          width="100%"
          height="500px"
          src={`https://docs.google.com/viewer?url=${fileUrl}&embedded=true`}
        />
      )}
      showFooter={false}
    />
  );
}
