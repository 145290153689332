import React from 'react';
import { useSelector } from 'react-redux';
import { SafeAnchor } from '@ahaui/react';

export default function SemesterInfo() {
  const currentSemester = useSelector(state => state.semester.currentSemester);

  if (!currentSemester) {
    return null;
  }

  const {
    absoluteCurrentWeek,
    relativeCurrentWeek,
    firstTeachingDayOfWeek,
    lastTeachingDayOfWeek,
    files,
  } = currentSemester;

  return (
    <div className="u-text200 u-textNoWrap">
      <span>
        Tuần&nbsp;
        <strong>{absoluteCurrentWeek}</strong>
      </span>
      &nbsp;
      <span>
        (Tuần học thứ&nbsp;
        <strong>{relativeCurrentWeek}</strong>
        )
      </span>
      <span>:&nbsp;</span>
      <SafeAnchor
        href={files?.[0] && `https://docs.google.com/viewer?url=${encodeURI(files.[0])}`}
        target="_blank"
      >
        {`${firstTeachingDayOfWeek.format('DD/MM')} - ${lastTeachingDayOfWeek.format('DD/MM')}`}
      </SafeAnchor>
    </div>
  );
}
