import React from 'react';
import { useSelector } from 'react-redux';

export default function withReduxStateWaiting(WrappedComponent, selector) {
  function ReduxStateWaitingWrapper(props) {
    const isStateReady = useSelector(selector);

    if (!isStateReady) return null;

    return (
      <WrappedComponent {...props} />
    );
  }

  return ReduxStateWaitingWrapper;
}
