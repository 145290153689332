/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import classNames from 'classnames';
import { Accordion, Icon } from '@ahaui/react';

export default function CustomAccordion({
  label,
  eventKey,
  activeKey,
  onToggleAccordion,
  children,
}) {
  const open = eventKey && (eventKey === activeKey);

  return (
    <Accordion
      activeKey={activeKey}
    >
      <div className="u-marginBottomExtraSmall u-border u-backgroundWhite u-roundedMedium u-overflowHidden">
        <div
          className="Button u-border u-borderTransparent u-backgroundPrimary u-textWhite u-flex u-alignItemsCenter u-justifyContentBetween u-cursorPointer"
          onClick={() => onToggleAccordion(eventKey)}
        >
          <div className="u-fontMedium">{label}</div>
          <div className="u-marginLeftTiny u-inlineBlock u-lineHeightNone">
            <Icon
              name="arrowDown"
              className={classNames(
                open && 'u-rotate180',
              )}
              size="extraSmall"
            />
          </div>
        </div>
        <Accordion.Collapse
          eventKey={eventKey}
        >
          <div className="u-borderTop u-borderTopLighter u-marginHorizontalSmall u-roundedBottomMedium u-paddingVerticalExtraSmall">
            {children}
          </div>
        </Accordion.Collapse>
      </div>
    </Accordion>
  );
}
