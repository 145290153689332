import React from 'react';
import { Form } from '@ahaui/react';

export default function FormInputGroup({
  id,
  register,
  errors,
  label,
  name,
  required,
  registerProps,
  formGroupProps,
  formLabelProps,
  formInputWrapperProps,
  feedback,
  renderFeedback,
  ...formInputProps
}) {
  const registered = register?.(name, {
    required,
    ...registerProps,
  });

  return (
    <Form.Group
      controlId={id}
      requiredControl={required}
      {...formGroupProps}
    >
      {label && (
        <Form.Label
          {...formLabelProps}
        >
          {label}
        </Form.Label>
      )}
      <div
        className="u-flexGrow1"
        {...formInputWrapperProps}
      >
        <Form.Input
          name={name}
          isInvalid={!!errors?.[name]}
          {...formInputProps}
          {...registered}
        />
        {errors?.[name] && (
        <Form.Feedback type="invalid">
          {errors[name].message}
        </Form.Feedback>
        )}
        {renderFeedback && feedback && renderFeedback(feedback)}
      </div>
    </Form.Group>
  );
}
