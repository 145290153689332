import React from 'react';
import { EmptyState } from '@ahaui/react';
import { useSelector } from 'react-redux';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import { getProjectRequestStatus } from 'utils/message';
import EmptyRows from 'components/Common/EmptyRows';

export default function ProjectRequestsSection() {
  const projectRequests = useSelector(state => (
    state.project.projectRequests
      ? orderBy(state.project.projectRequests, 'semester', 'desc')
      : null
  ));

  const renderProjectRequests = () => {
    const numberOfCols = 7;
    const numberOfRows = 3;

    if (!projectRequests) {
      return (
        <EmptyRows
          numberOfCols={numberOfCols}
          numberOfRows={numberOfRows}
        />
      );
    }
    if (projectRequests.length === 0) {
      return (
        <tr>
          <td colSpan={numberOfCols} className="u-backgroundWhite">
            <EmptyState name="general" className="u-widthFull">
              <EmptyState.Description>
                Không có nguyện vọng nào
              </EmptyState.Description>
            </EmptyState>
          </td>
        </tr>
      );
    }
    return projectRequests.map((request) => (
      <tr key={request.id}>
        <td className="u-textNoWrap" style={{ width: '1%' }}>{request.semester}</td>
        <td>{request.courseId}</td>
        <td>{request.courseName}</td>
        <td>
          {request.deadline && (
            <>
              <div>{request.deadline.name}</div>
              {request.deadline.startDate && request.deadline.endDate && (
                <div>
                  <span>{moment(request.deadline.startDate).format('Từ DD-MM-YYYY')}</span>
                  <span>{moment(request.deadline.endDate).format(' đến DD-MM-YYYY')}</span>
                </div>
              )}
            </>
          )}
        </td>
        <td className="u-textNoWrap">
          <div>{request.finalTeacherX?.fullName || request.finalCompanyX?.name || 'N/A'}</div>
          {request.finalOrder && (
            <div>{`Nguyện vọng ${request.finalOrder}`}</div>
          )}
        </td>
        <td>{getProjectRequestStatus(request.status)}</td>
        {/* TODO: cancel request action */}
        <td>N/A</td>
      </tr>
    ));
  };

  return (
    <div className="u-backgroundWhite u-border u-roundedLarge u-shadowLarge u-paddingSmall">
      <div className="u-text500 u-fontMedium u-marginBottomSmall">Danh sách nguyện vọng</div>
      <div className="u-marginVerticalExtraSmall u-borderLeft u-borderRight u-borderBottom u-overflowHorizontalAuto">
        <table className="Table Table--striped Table--hoverable u-backgroundWhite u-textDark u-text200">
          <thead className="u-textNoWrap">
            <tr>
              <th scope="col" className="u-textNoWrap" style={{ width: '1%' }}>Học kỳ</th>
              <th scope="col">Mã HP</th>
              <th scope="col">Tên HP</th>
              <th scope="col">Deadline</th>
              <th scope="col">Phân công</th>
              <th scope="col">Kết quả</th>
              <th scope="col">Thao tác</th>
            </tr>
          </thead>
          <tbody>
            {renderProjectRequests()}
          </tbody>
        </table>
      </div>
    </div>
  );
}
