import React from 'react';
import { Button } from '@ahaui/react';
import studentImage from 'assets/images/landing/student.svg';
import teacherImage from 'assets/images/landing/teacher.svg';
import companyImage from 'assets/images/landing/company.svg';

export default function CoverSection() {
  return (
    <section
      className="u-backgroundTeal50 u-positionRelative u-paddingVerticalExtraLargePlus"
      style={{ marginTop: -42 }}
    >
      <div className="Container u-marginBottomExtraLargePlus">
        <h2 className="u-text800 lg:u-text900 u-textCenter lg:u-marginBottomLarge">
          Hệ thống tích hợp 3 trong 1
        </h2>
        <p className="u-textCenter lg:u-paddingHorizontalExtraLargePlus">
          <strong>Hệ thống Quản lý Đại học Trực tuyến </strong>
          <span>cung cấp đầy đủ những chức năng cần thiết nhất cho sinh viên, giảng viên của trường Đại học Bách khoa Hà Nội, và các doanh nghiệp liên kết với trường.</span>
        </p>
      </div>
      <div className="">
        <div className="Container">
          <div className="Grid u-paddingBottomLarge">
            <div className="u-sizeFull lg:u-size4of12 u-marginTopExtraLarge lg:u-marginTopNone">
              <div className="u-backgroundWhite u-roundedLarge u-shadowLarge u-paddingMedium u-flex u-flexColumn u-alignItemsCenter u-textCenter u-heightFull">
                <div className="u-widthLarge u-heightLarge u-positionRelative u-marginBottomSmall">
                  <img
                    className="u-positionAbsolute u-positionBottom u-positionHorizontalCenter"
                    src={studentImage}
                    alt=""
                  />
                </div>
                <div className="u-marginBottomExtraSmall u-fontMedium u-text400">
                  Dành cho Sinh viên
                </div>
                <div className="u-text200 u-textGray u-marginBottomSmall">
                  Tra cứu thông tin đồ án, đăng ký nguyện vọng đồ án, yêu cầu biểu mẫu online, ... và nhiều chức năng quan trọng khác.
                </div>
                <div>
                  <Button>Truy cập</Button>
                </div>
              </div>
            </div>
            <div className="u-sizeFull lg:u-size4of12 u-marginTopExtraLarge lg:u-marginTopNone">
              <div className="u-backgroundWhite u-roundedLarge u-shadowLarge u-paddingMedium u-flex u-flexColumn u-alignItemsCenter u-textCenter u-heightFull">
                <div className="u-widthLarge u-heightLarge u-positionRelative u-marginBottomSmall">
                  <img
                    className="u-positionAbsolute u-positionBottom u-positionHorizontalCenter"
                    src={teacherImage}
                    alt=""
                  />
                </div>
                <div className="u-marginBottomExtraSmall u-fontMedium u-text400">
                  Dành cho Giảng viên
                </div>
                <div className="u-text200 u-textGray u-marginBottomSmall">
                  Quản lý mọi nghiệp vụ giảng dạy của giảng viên như: thời khoá biểu, phân công giảng dạy, phân công đồ án, tra cứu thông tin, ...
                </div>
                <div>
                  <Button>Truy cập</Button>
                </div>
              </div>
            </div>
            <div className="u-sizeFull lg:u-size4of12 u-marginTopExtraLarge lg:u-marginTopNone">
              <div className="u-backgroundWhite u-roundedLarge u-shadowLarge u-paddingMedium u-flex u-flexColumn u-alignItemsCenter u-textCenter u-heightFull">
                <div className="u-widthLarge u-heightLarge u-positionRelative u-marginBottomSmall">
                  <img
                    className="u-positionAbsolute u-positionBottom u-positionHorizontalCenter"
                    src={companyImage}
                    alt=""
                  />
                </div>
                <div className="u-marginBottomExtraSmall u-fontMedium u-text400">
                  Dành cho Doanh nghiệp
                </div>
                <div className="u-text200 u-textGray u-marginBottomSmall">
                  Các doanh nghiệp có thể đăng ký liên kết với nhà trường thông qua những dự án thực tập của sinh viên.
                </div>
                <div>
                  <Button>Truy cập</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
