import React from 'react';
import { Badge, Icon } from '@ahaui/react';

export default function FilterContainer({
  children,
}) {
  return (
    <div className="u-border u-roundedMedium u-paddingExtraSmall u-marginBottomExtraSmall u-flex u-alignItemsCenter u-flexWrap">
      <Badge
        variant="information_subtle"
        className="u-marginRightSmall"
      >
        <span className="u-flex u-alignItemsCenter u-paddingTiny">
          <Icon size="extraSmall" name="funnel" className="u-marginRightTiny" />
          <span>Bộ lọc</span>
        </span>
      </Badge>
      {children}
    </div>
  );
}
