import moment from 'moment';
import { SemesterAction } from 'constants/actions';
import { getSuccessAction } from 'utils/redux';
import { selectCurrentSemester } from 'store/selectors/semester.selector';

const getInitialState = () => ({
  semesters: null,
  currentSemester: null,
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case getSuccessAction(SemesterAction.GET_SEMESTERS): {
      const currentSemester = selectCurrentSemester({
        semesters: action.payload,
      });

      if (currentSemester) {
        const { startDate, startWeek, startTeachingWeek } = currentSemester;
        const currentWeek = 1 + Math.floor(
          moment.duration(
            moment().diff(moment(startDate)),
          ).asWeeks(),
        );
        currentSemester.absoluteCurrentWeek = currentWeek + startWeek - 1;
        currentSemester.relativeCurrentWeek = currentSemester.absoluteCurrentWeek - startTeachingWeek + 1;
        currentSemester.firstTeachingDayOfWeek = moment().startOf('week');
        currentSemester.lastTeachingDayOfWeek = currentSemester.firstTeachingDayOfWeek.clone().add(5, 'd');
      }

      return {
        ...state,
        semesters: action.payload || [],
        currentSemester,
      };
    }

    default:
      break;
  }
  return state;
};
