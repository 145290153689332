import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

export default function useSemesters(textOnly = true) {
  const currentSemester = useSelector(state => state.semester.currentSemester?.semester);
  const allSemestersObjects = useSelector(state => state.semester.semesters);
  const [semester, setSemester] = useState(null);

  const allSemesters = useMemo(() => {
    if (allSemestersObjects && textOnly) {
      return allSemestersObjects.map(sem => sem.semester);
    }
    return allSemestersObjects;
  }, [allSemestersObjects, textOnly]);

  useEffect(() => {
    if (currentSemester && !semester) {
      setSemester(currentSemester);
    }
  }, [currentSemester, semester]);

  return {
    currentSemester,
    allSemesters,
    semester,
    setSemester,
  };
}
