import { CompanyAction } from 'constants/actions';
import { getSuccessAction } from 'utils/redux';

const getInitialState = () => ({
  companies: null,
  feedbacks: null,
  ads: null,
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case getSuccessAction(CompanyAction.GET_COMPANY): {
      return {
        ...state,
        companies: {
          ...state.companies,
          [action.payload.id]: { ...action.payload },
        },
      };
    }

    case getSuccessAction(CompanyAction.GET_COMPANIES): {
      const dataById = {};
      action.payload.forEach((company) => {
        dataById[company.id] = company;
      });
      return {
        ...state,
        companies: {
          ...state.companies,
          ...dataById,
        },
      };
    }

    case getSuccessAction(CompanyAction.GET_COMPANY_FEEDBACK): {
      // Calculate average rate value
      const dataWithAvgRate = action.payload.map((feedback) => {
        // Ignore the location question, because it should not affect the overall rating
        const evaluableQuestions = feedback.questions?.filter(question => question.question !== 'Vị trí Công ty (Location)');
        const avgRateValue = evaluableQuestions
          ?.reduce(
            (total, question) => (total + ((question.rateValue / question.values.length) * 5)),
            0,
          ) / evaluableQuestions.length;
        const roundedAvgRateValue = Math.round((avgRateValue + Number.EPSILON) * 100) / 100;
        return {
          ...feedback,
          roundedAvgRateValue,
        };
      });
      return {
        ...state,
        feedbacks: {
          ...state.feedbacks,
          [action.extraPayload.companyId]: dataWithAvgRate,
        },
      };
    }

    case getSuccessAction(CompanyAction.GET_COMPANY_ADS): {
      return {
        ...state,
        ads: {
          ...state.ads,
          [action.extraPayload.companyId]: [...action.payload],
        },
      };
    }

    default:
      break;
  }
  return state;
};
