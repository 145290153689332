import React from 'react';
import { EmptyState } from '@ahaui/react';
import CenterLoading from 'components/Common/CenterLoading';
import AdsCard from 'components/Common/Ads/AdsCard';

export default function AdsSection({
  adsInfo,
}) {
  const renderAds = () => {
    if (!adsInfo) {
      return <CenterLoading />;
    }
    if (adsInfo.length === 0) {
      return (
        <EmptyState name="general" className="u-widthFull">
          <EmptyState.Description>
            Không có tin tức nào khả dụng
          </EmptyState.Description>
        </EmptyState>
      );
    }
    return (
      <div className="u-flex u-flexWrap">
        {adsInfo.map((ads) => (
          <div
            key={ads.id}
            className="u-marginRightSmall"
            style={{ width: 300 }}
          >
            <AdsCard ads={ads} />
          </div>
        ))}
      </div>
    );
  };
  return (
    <div
      className="u-marginTopExtraSmall u-positionRelative"
      style={{ minHeight: 200 }}
    >
      {renderAds()}
    </div>
  );
}
