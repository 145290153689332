import React from 'react';
import FormGroup from 'components/Common/Form/FormGroup';

export default function FilterSearchString({
  name,
  value,
  onChange,
  ...filterProps
}) {
  return (
    <FormGroup
      key={name}
      id={`bkeu-filter-input-${name}`}
      name={name}
      type="input"
      placeholder="Nhập từ khóa để tìm kiếm"
      sizeInput="small"
      formGroupProps={{
        className: 'u-marginRightSmall',
        style: {
          display: 'flex',
          width: 200,
          marginBottom: 0,
        },
      }}
      className="u-flexGrow1"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      {...filterProps}
    />
  );
}
