import React from 'react';
import { useSelector } from 'react-redux';
import { PageLayout } from '@ahaui/react';

import Header from './Header';
import Footer from './Footer';
import AdsSidebar from './Ads/AdsSidebar';

export default function Layout({
  children,
}) {
  const isLoggedIn = useSelector(state => state.user.isLoggedIn);

  return (
    <PageLayout
      style={{
        height: '100vh',
      }}
      className="u-positionRelative"
    >
      <PageLayout.Header
        className="u-zIndexPositive u-positionAbsolute u-widthFull"
      >
        <Header />
      </PageLayout.Header>
      <div
        id="scroll-container"
        className="u-flex u-flexGrow1 u-flexColumn u-overflowVerticalAuto"
        style={{
          paddingTop: 154,
          height: 1,
          zIndex: 0,
        }}
      >
        <PageLayout.Body className="u-backgroundDarkGray u-flexWrap">
          <div
            className="PageLayout-bodyInner u-flexGrow1 u-flex u-flexColumn"
          >
            {children}
          </div>
          {isLoggedIn && (
            <AdsSidebar />
          )}
        </PageLayout.Body>
        <PageLayout.Footer>
          <Footer />
        </PageLayout.Footer>
      </div>
    </PageLayout>
  );
}
