import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { EmptyState, SafeAnchor } from '@ahaui/react';
import { withCache, withBlock } from 'store/actions/hoc.action';
import { getCompanies } from 'store/actions/company.action';
import { showModal } from 'store/actions/modal.action';
import { ModalKey } from 'constants/common';
import { convertObjToArray } from 'utils/object';
import ExternalLink from 'components/Common/ExternalLink';
import EmptyRows from 'components/Common/EmptyRows';
import { SectionKey } from 'components/Modals/CompanyInfoModal';

export default function CompanyList() {
  const dispatch = useDispatch();
  const companies = useSelector(state => state.company.companies);
  // TODO: hide invisible companies
  const companyList = useMemo(() => (companies ? convertObjToArray(companies, false) : null), [companies]);

  const onCompanyClick = (companyId) => {
    dispatch(showModal(ModalKey.COMPANY_INFO, {
      companyId,
    }));
  };

  const onProjectTopicsClick = (companyId) => {
    dispatch(showModal(ModalKey.COMPANY_INFO, {
      companyId,
      defaultSection: SectionKey.PROJECT_TOPICS_SECTION,
    }));
  };

  const renderCompanies = () => {
    const numberOfCols = 7;
    const numberOfRows = 3;

    if (!companyList) {
      return (
        <EmptyRows
          numberOfCols={numberOfCols}
          numberOfRows={numberOfRows}
        />
      );
    }
    if (companyList.length === 0) {
      return (
        <tr>
          <td colSpan={numberOfCols} className="u-backgroundWhite">
            <EmptyState name="general" className="u-widthFull">
              <EmptyState.Description>
                Không có đồ án nào
              </EmptyState.Description>
            </EmptyState>
          </td>
        </tr>
      );
    }
    return companyList.map((company, index) => (
      <tr key={company.id}>
        <td className="u-textNoWrap" style={{ width: '1%' }}>{index + 1}</td>
        <td>
          <SafeAnchor
            className="u-fontMedium"
            onClick={() => onCompanyClick(company.id)}
          >
            {company.name}
          </SafeAnchor>
          <div>{company.fullName}</div>
        </td>
        <td>
          <ExternalLink href={company.website}>
            Truy cập
          </ExternalLink>
        </td>
        <td>
          <SafeAnchor
            onClick={() => onProjectTopicsClick(company.id)}
          >
            {company.topicNum}
          </SafeAnchor>
        </td>
        <td>{company.staffNum}</td>
        <td>{`${company.internNum} SV`}</td>
        <td>
          {company.contactUsers?.map((user, index) => (
            <div
              key={user.id}
              className={classNames(index !== 0 && 'u-marginTopExtraSmall')}
            >
              <div className="u-fontMedium">{user.fullName}</div>
              <div>{user.phoneNumber}</div>
              <div>{user.email}</div>
            </div>
          ))}
        </td>
      </tr>
    ));
  };

  useEffect(() => {
    dispatch(withCache(withBlock(getCompanies())));
  }, [dispatch]);

  return (
    <div className="u-paddingVerticalLarge u-paddingHorizontalSmall">
      <div className="u-backgroundWhite u-border u-roundedLarge u-shadowLarge u-paddingSmall">
        <div className="u-text500 u-fontMedium u-marginBottomSmall">Danh sách công ty</div>
        <div className="u-marginVerticalExtraSmall u-borderLeft u-borderRight u-borderBottom u-overflowHorizontalAuto">
          <table className="Table Table--striped Table--hoverable u-backgroundWhite u-textDark u-text200">
            <thead className="u-textNoWrap">
              <tr>
                <th scope="col" className="u-textNoWrap" style={{ width: '1%' }}>#</th>
                <th scope="col">Tên Công ty</th>
                <th scope="col">Website</th>
                <th scope="col">Số Đề tài</th>
                <th scope="col">Số NV</th>
                <th scope="col">Nguyện vọng</th>
                <th scope="col">Liên hệ</th>
              </tr>
            </thead>
            <tbody>
              {renderCompanies()}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
