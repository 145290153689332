import { ProjectAction } from 'constants/actions';
import { get, post } from 'utils/request';

export const getProjects = () => ({
  type: ProjectAction.GET_PROJECTS,
  promise: () => get('/students/me/projects'),
});

export const getProjectRequests = () => ({
  type: ProjectAction.GET_PROJECT_REQUESTS,
  promise: () => get('/students/me/project-requests'),
});

export const getProjectTopicsSchool = () => ({
  type: ProjectAction.GET_PROJECT_TOPICS_SCHOOL,
  promise: () => get('/project-topics', {
    topicType: 'school',
  }),
});

export const getProjectTopicsCompany = () => ({
  type: ProjectAction.GET_PROJECT_TOPICS_COMPANY,
  promise: () => get('/project-topics', {
    topicType: 'company',
  }),
});

export const getProjectPlagiarismRequests = () => ({
  type: ProjectAction.GET_PROJECT_PLAGIARISM_REQUESTS,
  promise: () => get('/students/me/plagiarism-requests'),
});

export const createProjectPlagiarismRequests = (fileUrl) => ({
  type: ProjectAction.CREATE_PROJECT_PLAGIARISM_REQUESTS,
  promise: () => post('/students/me/plagiarism-requests', {
    fileUrl,
  }),
});
