import { isFailureAction } from 'utils/redux';
import toast from 'utils/toast';

export default () => next => (action) => {
  if (isFailureAction(action.type) && !action.ignoreError) {
    toast.error(action.payload);
  }

  return next(action);
};
