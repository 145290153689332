import React from 'react';
import { Loader } from '@ahaui/react';

export function CenterLoading({
  hideText,
  text,
  duration,
  size,
}) {
  return (
    <div className="u-positionAbsolute u-positionFull u-flex u-alignContentCenter u-justifyContentCenter">
      <div className="u-flex u-alignItemsCenter u-justifyContentCenter u-flexColumn">
        <div className="u-flexShrink-0">
          <Loader size={size} duration={duration} />
        </div>
        {!hideText && <div className="u-marginTopMedium">{text || 'Vui lòng đợi…'}</div>}
      </div>
    </div>
  );
}

CenterLoading.defaultProps = {
  size: 'large',
};

export default CenterLoading;
