import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompany, getCompanyFeedbacks, getCompanyAds } from 'store/actions/company.action';
import { withCache, withBlock } from 'store/actions/hoc.action';
import ModalBase from 'components/Modals/ModalBase';
import CustomAccordion from 'components/Common/CustomAccordion';
import CenterLoading from 'components/Common/CenterLoading';

import InfoSection from './InfoSection';
import AboutSection from './AboutSection';
import ProjectTopicsSection from './ProjectTopicsSection';
import FeedbackSection from './FeedbackSection';
import AdsSection from './AdsSection';

export const SectionKey = {
  INFO_SECTION: 'INFO_SECTION',
  ABOUT_SECTION: 'ABOUT_SECTION',
  PROJECT_TOPICS_SECTION: 'PROJECT_TOPICS_SECTION',
  FEEDBACK_SECTION: 'FEEDBACK_SECTION',
  ADS_SECTION: 'ADS_SECTION',
};

export default function CompanyInfoModal({
  onModalClose,
  companyId,
  defaultSection = SectionKey.INFO_SECTION,
}) {
  const dispatch = useDispatch();
  const companies = useSelector(state => state.company.companies);
  const feedbacks = useSelector(state => state.company.feedbacks);
  const ads = useSelector(state => state.company.ads);
  const [currentSection, setCurrentSection] = useState(defaultSection);

  const companyInfo = (companyId && companies && companies[companyId]) || null;
  const feedbacksInfo = (companyId && feedbacks && feedbacks[companyId]) || null;
  const adsInfo = (companyId && ads && ads[companyId]) || null;

  const onSectionLabelClick = (eventKey) => {
    if (eventKey === currentSection) {
      setCurrentSection(null);
      return;
    }
    setCurrentSection(eventKey);
  };

  useEffect(() => {
    dispatch(
      withCache(
        withBlock(getCompany(companyId)),
        companyId,
      ),
    );
    dispatch(
      withCache(
        withBlock(getCompanyFeedbacks(companyId)),
        companyId,
      ),
    );
    dispatch(
      withCache(
        withBlock(getCompanyAds(companyId)),
        companyId,
      ),
    );
  }, [companyId, dispatch]);

  return (
    <ModalBase
      headerText="Thông tin công ty"
      size="container"
      closable
      onHide={onModalClose}
      body={(
        <div
          className="u-positionRelative"
          style={{ minHeight: 221 }}
        >
          {companyInfo ? (
            <>
              <CustomAccordion
                activeKey={currentSection}
                eventKey={SectionKey.INFO_SECTION}
                onToggleAccordion={onSectionLabelClick}
                label="Thông tin chung"
              >
                <InfoSection companyInfo={companyInfo} />
              </CustomAccordion>
              <CustomAccordion
                activeKey={currentSection}
                eventKey={SectionKey.ABOUT_SECTION}
                onToggleAccordion={onSectionLabelClick}
                label="Giới thiệu về Công ty"
              >
                <AboutSection companyInfo={companyInfo} />
              </CustomAccordion>
              <CustomAccordion
                activeKey={currentSection}
                eventKey={SectionKey.PROJECT_TOPICS_SECTION}
                onToggleAccordion={onSectionLabelClick}
                label="Danh sách Đề tài thực tập"
              >
                <ProjectTopicsSection companyInfo={companyInfo} />
              </CustomAccordion>
              <CustomAccordion
                activeKey={currentSection}
                eventKey={SectionKey.FEEDBACK_SECTION}
                onToggleAccordion={onSectionLabelClick}
                label="Phản hồi của Sinh viên"
              >
                <FeedbackSection feedbacksInfo={feedbacksInfo} />
              </CustomAccordion>
              <CustomAccordion
                activeKey={currentSection}
                eventKey={SectionKey.ADS_SECTION}
                onToggleAccordion={onSectionLabelClick}
                label="Tin tức đã đăng"
              >
                <AdsSection adsInfo={adsInfo} />
              </CustomAccordion>
            </>
          ) : (
            <CenterLoading />
          )}
        </div>
      )}
      showFooter={false}
    />
  );
}
