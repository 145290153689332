import React from 'react';
import { Button, Icon } from '@ahaui/react';
import formatter from 'utils/formatter';
import FormGroup from 'components/Common/Form/FormGroup';

export default function Pagination({
  totalItems,
  itemsPerPage,
  pageOffset,
  firstVisibleRow,
  lastVisibleRow,
  onPageOffsetChange,
  onItemsPerPageChange,
  disabled,
}) {
  const onBack = (offset) => {
    const newPageOffset = formatter.forceInRange(offset ?? (pageOffset - itemsPerPage), 0, totalItems);
    onPageOffsetChange(newPageOffset);
  };

  const onNext = (offset) => {
    const newPageOffset = formatter.forceInRange(offset ?? (pageOffset + itemsPerPage), 0, totalItems);
    onPageOffsetChange(newPageOffset);
  };

  const renderBackAllButton = () => (
    <Button
      size="small"
      variant="link"
      onlyIcon
      className="u-marginRightExtraSmall"
      onClick={() => onBack(0)}
      disabled={disabled || pageOffset === 0}
    >
      <Button.Icon>
        <Icon
          size="small"
          name="playCircle"
          className="u-flipHorizontal"
        />
      </Button.Icon>
    </Button>
  );

  const renderBackButton = () => (
    <Button
      size="small"
      variant="link"
      onlyIcon
      className="u-marginRightExtraSmall"
      onClick={() => onBack()}
      disabled={disabled || pageOffset === 0}
    >
      <Button.Icon>
        <Icon
          size="small"
          name="arrowDropleftCircle"
        />
      </Button.Icon>
    </Button>
  );

  const renderNextButton = () => (
    <Button
      size="small"
      variant="link"
      onlyIcon
      className="u-marginLeftExtraSmall"
      onClick={() => onNext()}
      disabled={disabled || lastVisibleRow >= totalItems}
    >
      <Button.Icon>
        <Icon
          size="small"
          name="arrowDroprightCircle"
        />
      </Button.Icon>
    </Button>
  );

  const renderNextAllButton = () => (
    <Button
      size="small"
      variant="link"
      onlyIcon
      className="u-marginLeftExtraSmall"
      onClick={() => onNext(
        Math.floor(totalItems / itemsPerPage) * itemsPerPage,
      )}
      disabled={disabled || lastVisibleRow >= totalItems}
    >
      <Button.Icon>
        <Icon
          size="small"
          name="playCircle"
        />
      </Button.Icon>
    </Button>
  );

  const renderItemsInfo = () => (
    <span className="u-marginHorizontalExtraSmall u-text200">
      <span className="u-fontMedium">{`${firstVisibleRow}-${lastVisibleRow}`}</span>
      <span> trên tổng số </span>
      <span className="u-fontMedium">{totalItems}</span>
    </span>
  );

  return (
    <div className="u-flex u-flexGrow1 u-flexWrap u-justifyContentBetween">
      <div className="u-flex u-alignItemsCenter">
        {renderBackAllButton()}
        {renderBackButton()}
        {renderItemsInfo()}
        {renderNextButton()}
        {renderNextAllButton()}
      </div>
      <div>
        <FormGroup
          id="bkeu-paging-ipp-select"
          label="Số dòng"
          name="itemsPerPage"
          type="select"
          sizeInput="small"
          formGroupProps={{
            style: {
              display: 'flex',
              alignItems: 'center',
              width: 150,
              marginBottom: 0,
            },
          }}
          formLabelProps={{
            className: 'u-text200 u-paddingRightExtraSmall',
            style: {
              marginBottom: 0,
            },
          }}
          className="u-flexGrow1"
          value={itemsPerPage}
          onChange={(e) => onItemsPerPageChange(e.target.value)}
          options={[30, 50, 100, 200, 500]}
          disabled={disabled}
        />
      </div>
    </div>
  );
}
