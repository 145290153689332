import React, { useEffect } from 'react';
import {
  Route, Switch, Redirect,
} from 'react-router-dom';
import { ToastContainer } from '@ahaui/react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteGuardRule } from 'constants/common';
import { withBlock } from 'store/actions/hoc.action';
import { getUserInfo } from 'store/actions/user.action';
import { getSemesters } from 'store/actions/semester.action';
import { isNotEmptyArray } from 'utils/object';
import ModalContainer from 'components/Modals/ModalContainer';
import Layout from 'components/Common/Layout';
import LoadingOverlay from 'components/Common/LoadingOverlay';

import Landing from './Landing';
import StudentProjects from './StudentProjects';
import ProjectRegistration from './ProjectRegistration';
import CompanyList from './CompanyList';
import ProjectOrientations from './ProjectOrientations';
import ProjectReferences from './ProjectReferences';
import ProjectPlagiarism from './ProjectPlagiarism';
import TimeTable from './TimeTable';
import OnlineForm from './OnlineForm';
import UserInfo from './UserInfo';
import FAQ from './FAQ';

const routesConfig = [
  {
    auth: RouteGuardRule.UNRESTRICTED,
    exact: true,
    path: '/',
    component: Landing,
    key: 'landing',
  },
  {
    auth: RouteGuardRule.AUTH_ONLY,
    exact: true,
    path: '/projects/project-list',
    component: StudentProjects,
    key: 'project-list',
  },
  {
    auth: RouteGuardRule.AUTH_ONLY,
    exact: true,
    path: '/projects/project-registration',
    component: ProjectRegistration,
    key: 'project-registration',
  },
  {
    auth: RouteGuardRule.AUTH_ONLY,
    exact: true,
    path: '/projects/project-references',
    component: ProjectReferences,
    key: 'project-references',
  },
  {
    auth: RouteGuardRule.AUTH_ONLY,
    exact: true,
    path: '/projects/project-orientations',
    component: ProjectOrientations,
    key: 'project-orientations',
  },
  {
    auth: RouteGuardRule.AUTH_ONLY,
    exact: true,
    path: '/projects/company-list',
    component: CompanyList,
    key: 'company-list',
  },
  {
    auth: RouteGuardRule.AUTH_ONLY,
    exact: true,
    path: '/projects/project-plagiarism',
    component: ProjectPlagiarism,
    key: 'project-plagiarism',
  },
  {
    auth: RouteGuardRule.AUTH_ONLY,
    exact: true,
    path: '/time-table',
    component: TimeTable,
    key: 'time-table',
  },
  {
    auth: RouteGuardRule.AUTH_ONLY,
    exact: true,
    path: '/online-form',
    component: OnlineForm,
    key: 'online-form',
  },
  {
    auth: RouteGuardRule.AUTH_ONLY,
    exact: true,
    path: '/user-info',
    component: UserInfo,
    key: 'user-info',
  },
  {
    auth: RouteGuardRule.AUTH_ONLY,
    exact: true,
    path: '/faq',
    component: FAQ,
    key: 'faq',
  },
  {
    auth: RouteGuardRule.UNAUTH_ONLY,
    redirect: true,
    to: '/',
    key: 'redirect-landing',
  },
  {
    auth: RouteGuardRule.AUTH_ONLY,
    redirect: true,
    to: '/projects/project-list',
    key: 'redirect-project-list',
  },
];

function App() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(state => state.user.isLoggedIn);
  const isBlocking = useSelector(state => isNotEmptyArray(state.app.blockingTasks));

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(withBlock(getUserInfo()));
      dispatch(withBlock(getSemesters()));
    }
  }, [isLoggedIn, dispatch]);

  const renderRoutes = () => routesConfig
    .filter((route) => {
      if (route.auth === RouteGuardRule.UNRESTRICTED) {
        return true;
      }
      if (route.auth === RouteGuardRule.AUTH_ONLY) {
        return isLoggedIn;
      }
      if (route.auth === RouteGuardRule.UNAUTH_ONLY) {
        return !isLoggedIn;
      }
      return false;
    })
    .map(({ auth, redirect, ...props }) => (redirect ? (
      <Redirect
        {...props}
      />
    ) : (
      <Route
        {...props}
      />
    )));

  return (
    <main className="App u-positionRelative">
      <Layout>
        <Switch>
          {renderRoutes()}
        </Switch>
      </Layout>
      <ModalContainer />
      <ToastContainer />
      {isBlocking && <LoadingOverlay />}
    </main>
  );
}

export default App;
