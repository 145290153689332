export const StorageKey = {
  AUTHENTICATION: 'AUTHENTICATION',
  LAST_PLAGIARISM_CHECK: 'LAST_PLAGIARISM_CHECK',
};

export const ModalKey = {
  LOG_IN: 'LOG_IN',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  GOOGLE_DOCS_VIEWER: 'GOOGLE_DOCS_VIEWER',
  COMPANY_INFO: 'COMPANY_INFO',
  CLASS_INFO: 'CLASS_INFO',
  FORM: 'FORM',
};

export const KeyboardKey = {
  ENTER: 'Enter',
};

export const RouteGuardRule = {
  AUTH_ONLY: 'AUTH_ONLY',
  UNAUTH_ONLY: 'UNAUTH_ONLY',
  UNRESTRICTED: 'UNRESTRICTED',
};

export const LOGO_RATIO = 1481 / 2185;

export const FileMetadata = {
  PDF: {
    EXTENSION: '.pdf',
    MIME_TYPE: 'application/pdf',
  },
};

export const SearchAlgorithm = {
  EXACT_MATCH: 'EXACT_MATCH',
  FUZZY: 'FUZZY',
};

export const StatusData = {
  STATUS_DATA_UNDEFINED: -2,
  STATUS_DATA_REJECTED: -1,
  STATUS_DATA_WAITING: 0,
  STATUS_DATA_CONFIRMED: 1,
  STATUS_DATA_SUBMITTED: 2,
  STATUS_DATA_DONE: 3,
  STATUS_DATA_PROCESSING: 4,
  STATUS_DATA_CANCELLED: 5,
  STATUS_DATA_SUSPENDED: 6,
};

export const CompanyType = {
  COMPANY_TYPE_OTHERS: 0,
  COMPANY_TYPE_PRIVATE_SHARES: 1,
  COMPANY_TYPE_CORPORATION: 2,
  COMPANY_TYPE_FOREIGN_CAPITAL: 3,
  COMPANY_TYPE_STARTUP: 4,
  COMPANY_TYPE_STATE_ORGANIZATION: 5,
};

export const ProductType = {
  PRODUCT_TYPE_OUT_SOURSE: 1,
  PRODUCT_TYPE_PRODUCT_SERVICE: 2,
  PRODUCT_TYPE_STARTUP: 3,
  PRODUCT_TYPE_RESEARCH: 4,
  PRODUCT_TYPE_GAMES: 5,
  PRODUCT_TYPE_ECOMMERCE: 6,
  PRODUCT_TYPE_FINTECH: 7,
  PRODUCT_TYPE_EDUCATION: 8,
  PRODUCT_TYPE_EGOV: 9,
  PRODUCT_TYPE_EHEALTH: 10,
};

export const CompanyRole = {
  ROLE_COMPANY_STAFF: 0,
  ROLE_COMPANY_CONTACT: 1,
  ROLE_COMPANY_MANAGER: 2,
};

export const UserStatus = {
  USER_STATUS_NEW: -1,
  USER_STATUS_NORMAL: 0,
  USER_STATUS_DISABLED: 1,
  USER_STATUS_GRADUATED: 2,
  USER_STATUS_QUIT: 3,
  USER_STATUS_RETURNED_TO_COMPANY: 4,
  USER_STATUS_SUSPENSED: 5,
};

export const InternType = {
  INTERN_FULLTIME: 0,
  INTERN_PARTTIME: 1,
};

export const TopicType = {
  TOPIC_TYPE_NEW_TECHNO: 1,
  TOPIC_TYPE_DEMO_PRODUCT: 2,
  TOPIC_TYPE_REAL_PROJECT: 3,
  TOPIC_TYPE_TRAINING: 4,
  TOPIC_TYPE_OTHERS: 5,
};

export const TeachingType = {
  TYPE_TEACHING_OFFLINE: 1,
  TYPE_TEACHING_BLEARNING: 2,
  TYPE_TEACHING_ONLINE: 3,
};

export const ProjectType = {
  PROJECT_TYPE_PROJECT: 0,
  PROJECT_TYPE_GRADUATION: 1,
  PROJECT_TYPE_INTERN: 2,
  PROJECT_TYPE_GRADUATION_INTERN: 3,
  PROJECT_TYPE_PROFESSIONAL_MASTER: 4,
  PROJECT_TYPE_RESEARCH_MASTER: 5,
  PROJECT_TYPE_PHD: 6,
};

export const FormStatus = {
  FORM_STATUS_CANCELLED: -2,
  FORM_STATUS_REJECT: -1,
  FORM_STATUS_WATTING: 0,
  FORM_STATUS_PROCESSING: 1,
  FORM_STATUS_FINISH: 2,
};

export const FormType = {
  FORM_TYPE_CNSV: 1,
  FORM_TYPE_GGT: 2,
  FORM_TYPE_GXN: 3,
  FORM_TYPE_BDSV: 4,
  FORM_TYPE_DTSV: 5,
  FORM_TYPE_CNTNSV: 6,
  FORM_TYPE_GGTCT: 7,
  FORM_TYPE_CNSVCT: 8,
  FORM_TYPE_MDC: 9,
  FORM_TYPE_CDTD: 10,
  FORM_TYPE_BMTSV: 11,
  FORM_TYPE_RHS: 12,
  FORM_TYPE_TTDN: 13,
  FORM_TYPE_HTCTDT: 14,
  FORM_TYPE_DKHT: 15,
  FORM_TYPE_GUQ: 16,
};
