import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { Button } from '@ahaui/react';
import { FormType } from 'constants/common';
import { getFormName } from 'utils/message';

import ModalBase from 'components/Modals/ModalBase';
import FormCNSV from './FormCNSV';

const FormComponentMapping = {
  [FormType.FORM_TYPE_CNSV]: FormCNSV,
};

export default function FormModal({
  onModalClose,
  formType,
  formData,
}) {
  const formRef = useRef();
  const FormComponent = FormComponentMapping[formType];

  return (
    <ModalBase
      headerText={getFormName(formType)}
      size="extraLarge"
      closable
      onHide={onModalClose}
      body={(
        <div className="u-flex u-flexColumn u-justifyContentCenter u-alignItemsCenter u-overflowAuto">
          <div className="u-border u-shadowMedium u-backgroundWhite u-marginBottomSmall">
            <div ref={formRef}>
              <FormComponent
                formData={formData}
              />
            </div>
          </div>
          <ReactToPrint
            content={() => formRef.current}
            trigger={() => (
              <Button>In Đơn</Button>
            )}
          />
        </div>
      )}
      showFooter={false}
    />
  );
}
