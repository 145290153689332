import React, { useState } from 'react';
import copy from 'copy-to-clipboard';
import { v4 as uuidv4 } from 'uuid';
import { Icon, Button, Overlay } from '@ahaui/react';

import CopyTooltip from './CopyTooltip';

function CopyButton({
  textToCopy,
  ...buttonProps
}) {
  const [copiedNonce, setCopiedNonce] = useState(uuidv4());

  const onCopyClick = () => {
    copy(textToCopy);
    setCopiedNonce(uuidv4());
  };

  return (
    <Button
      size="small"
      variant="link"
      onlyIcon
      onClick={onCopyClick}
      {...buttonProps}
    >
      <Overlay.Trigger
        placement="right"
        hoverOverlay
        delay={{ show: 0, hide: 1 }}
        overlay={props => (
          <CopyTooltip
            copiedNonce={copiedNonce}
            {...props}
          />
        )}
      >
        <Button.Icon
          className="u-textLight hover:u-textGray"
        >
          <Icon name="copy" />
        </Button.Icon>
      </Overlay.Trigger>
    </Button>
  );
}

export default React.memo(CopyButton);
