import React from 'react';

import CoverSection from './CoverSection';
import TeacherSection from './TeacherSection';
import StudentSection from './StudentSection';
import WalkthroughSection from './WalkthroughSection';
// import ContactSection from './ContactSection';

export default function Landing() {
  return (
    <>
      <CoverSection />
      <TeacherSection />
      <StudentSection />
      <WalkthroughSection />
      {/* <ContactSection /> */}
    </>
  );
}
