import {
  useState, useRef, useEffect, useMemo,
} from 'react';
import Fuse from 'fuse.js';
import { SearchAlgorithm } from 'constants/common';

const defaultOptions = {
  shouldSort: true,
  threshold: 0.5,
  location: 0,
  distance: 100,
  minMatchCharLength: 1,
};

const postProcessSearchResults = (results) => {
  if (!results) return results;
  return results.map((result) => result.item);
};

export default function useFilter({
  items,
  algorithm = SearchAlgorithm.FUZZY,
  options,
}) {
  const [searchEngine, setSearchEngine] = useState(null);
  const [searchPattern, setSearchPattern] = useState('');
  const optionRef = useRef();
  optionRef.current = {
    ...defaultOptions,
    ...options,
  };

  const filteredItems = useMemo(() => {
    if (
      !searchEngine?.search
      || !items
      || !searchPattern
    ) {
      return items;
    }
    return postProcessSearchResults(searchEngine.search(searchPattern));
  }, [items, searchPattern, searchEngine]);

  useEffect(() => {
    if (!items) {
      return;
    }
    if (algorithm === SearchAlgorithm.FUZZY) {
      setSearchEngine(new Fuse(items, optionRef.current));
    }
  }, [items, algorithm]);

  return {
    searchEngine,
    filteredItems,
    searchPattern,
    onSearchPatternChange: setSearchPattern,
  };
}
