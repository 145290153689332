import React from 'react';
import image1 from 'assets/images/landing/student/1.png';
import image2 from 'assets/images/landing/student/2.png';
import image3 from 'assets/images/landing/student/3.png';

export default function StudentSection() {
  return (
    <section
      className="u-backgroundWhite u-positionRelative u-paddingVerticalExtraLarge"
    >
      <div className="Container">
        <h2 className="u-text800 lg:u-text900 u-textCenter lg:u-marginBottomLarge">
          Dịch vụ cho Sinh viên
        </h2>
        <div className="Grid Grid--smallGutter">
          {[
            {
              title: 'Tra cứu thông tin đồ án',
              description: 'Tra cứu thông tin phân công đồ án, tham khảo các Đồ án được nộp và lưu trữ online',
              img: image1,
            },
            {
              title: 'Yêu cầu biểu mẫu online',
              description: 'Điền thông tin và gửi yêu cầu các loại biểu mẫu online. Viện sẽ xử lý và thông báo qua email cho Sinh viên khi hoàn thành, Sinh viên chỉ lên một lần để nhận kết quả',
              img: image2,
            },
            {
              title: 'Đăng ký nguyện vọng Đồ án',
              description: 'Tham khảo các hướng đề tài của Giáo viên, đăng ký nguyện vọng là Đồ án với giáo viên và đề tài mong muốn',
              img: image3,
            },
          ].map(({ title, description, img }) => (
            <div
              key={title}
              className="u-sizeFull md:u-size1of3 u-paddingBottomSmall"
            >
              <div
                className="u-flex u-flexColumn u-alignItemsCenter u-justifyContentStart u-shadowMedium u-paddingSmall u-roundedLarge u-heightFull"
              >
                <img
                  className="u-marginBottomSmall"
                  src={img}
                  style={{ width: 80 }}
                  alt=""
                />
                <div className="u-textCenter u-marginBottomSmall u-text400 u-fontMedium">{title}</div>
                <div className="u-textCenter">{description}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
