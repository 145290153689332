import CryptoJS from 'crypto-js';

const replaceWhitespaceAndNbsp = (str) => {
  const re = new RegExp(String.fromCharCode(160), 'g');
  return str.trim().replace(re, ' ').replace(/\s+/g, ' ');
};

/**
 * Generate 2-character name from a long name.
 *
 * Example:
 *
 *    getShortName('Nam Dao'); // 'ND'
 *    getShortName('namdaoduy'); // 'NA'
 *    getShortName('N'); // 'N'
 *    getShortName(''); // ' '
 *
 * @param  {string} name Long name or username.
 * @param  {boolean} [upperCase=true] If true, transform the short name to upper case.
 */
const getShortName = (name, upperCase = true) => {
  let shortName = ' ';
  if (!name) return shortName;
  const nameParts = replaceWhitespaceAndNbsp(name).split(' ');
  if (nameParts.length < 2) {
    shortName = nameParts[0].substring(0, 2);
  } else {
    shortName = `${nameParts[0].substring(0, 1)}${nameParts[1].substring(0, 1)}`;
  }
  if (upperCase) {
    return shortName.toUpperCase();
  }
  return shortName;
};

const encryptPassword = (username, password) => {
  const encryptKey = CryptoJS.enc.Utf8.parse(CryptoJS.MD5(`${username}.${password}`));
  let encryptedPassword = null;
  try {
    encryptedPassword = CryptoJS.DES.encrypt(password, encryptKey, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();
  } catch (error) {
    console.error(error);
  }
  return encryptedPassword;
};

const getExternalHref = (href, SSL = true) => {
  if (!href) return href;
  if (href.startsWith('http')) return href;
  return SSL ? `https://${href}` : `http://${href}`;
};

const getRatingColorClassName = (rating, maxRating = 5) => {
  const percent = (rating / parseFloat(maxRating)) * 100;
  if (percent >= 0 && percent < 20) {
    return 'u-textRed300';
  }
  if (percent >= 20 && percent < 40) {
    return 'u-textOrange300';
  }
  if (percent >= 40 && percent < 60) {
    return 'u-textYellow300';
  }
  if (percent >= 60 && percent < 80) {
    return 'u-textLime300';
  }
  if (percent >= 80 && percent <= 100) {
    return 'u-textLightGreen300';
  }
  return '';
};

const forceInRange = (value, minValue, maxValue) => {
  const forcedMax = Math.min(maxValue, value);
  const forcedMin = Math.max(minValue, forcedMax);
  return forcedMin;
};

const defaultTagsFormatter = (tags) => {
  const formattedTags = [];

  tags.forEach((tag) => {
    const splittedTags = tag.split(',');

    splittedTags.forEach((singleTag) => {
      const trimmedTag = replaceWhitespaceAndNbsp(singleTag);

      if (trimmedTag) {
        formattedTags.push(trimmedTag);
      }
    });
  });

  return formattedTags;
};

export default {
  getShortName,
  replaceWhitespaceAndNbsp,
  encryptPassword,
  getExternalHref,
  getRatingColorClassName,
  forceInRange,
  defaultTagsFormatter,
};
