import React from 'react';
import FormGroup from 'components/Common/Form/FormGroup';

export default function FilterSelectBox({
  name,
  label,
  value,
  options,
  onChange,
  ...filterProps
}) {
  return (
    <FormGroup
      key={name}
      id={`bkeu-filter-select-${name}`}
      label={label}
      name={name}
      type="select"
      sizeInput="small"
      formGroupProps={{
        className: 'u-marginRightSmall',
        style: {
          display: 'flex',
          width: 200,
          marginBottom: 0,
        },
      }}
      formLabelProps={{
        className: 'u-text200 u-paddingVerticalTiny u-paddingTiny',
        style: {
          marginBottom: 0,
        },
      }}
      className="u-flexGrow1"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      disabled={!value || !(options?.length > 0)}
      options={options}
      {...filterProps}
    />
  );
}
