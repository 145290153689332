import React from 'react';
import { Skeleton } from '@ahaui/react';

function EmptyRows({
  numberOfCols = 1,
  numberOfRows = 1,
}) {
  const cols = Array.from(Array(numberOfCols).keys());
  const rows = Array.from(Array(numberOfRows).keys());

  return rows.map(row => (
    <tr key={row}>
      {cols.map(col => (
        <td key={col}><Skeleton width="50%" /></td>
      ))}
    </tr>
  ));
}

export default React.memo(EmptyRows);
