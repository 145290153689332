import { UserAction } from 'constants/actions';
import { get, post } from 'utils/request';
import formatter from 'utils/formatter';

export const logInWithPassword = ({
  username,
  password,
}) => ({
  type: UserAction.LOG_IN_WITH_PASSWORD,
  promise: () => post('/auth/login', {
    username,
    password: formatter.encryptPassword(username, password),
  }),
});

export const getUserInfo = () => ({
  type: UserAction.GET_USER_INFO,
  promise: () => get('/students/me'),
});

export const resetPassword = () => ({
  type: UserAction.LOG_IN_WITH_PASSWORD,
  promise: Promise.reject(new Error('Not Implemented')),
});

export const logOut = () => ({
  type: UserAction.LOG_OUT,
  promise: () => post('/auth/logout'),
});

export const getVisibleAds = () => ({
  type: UserAction.GET_VISIBLE_ADS,
  promise: () => get('/ads'),
});

export const getClasses = (semester) => ({
  type: UserAction.GET_CLASSES,
  promise: () => get('/students/me/classes', {
    semester,
  }),
  payload: {
    semester,
  },
});
