import React, { useState } from 'react';
import TabGroup from 'components/Common/TabGroup';

import UploadTab from './UploadTab';
import ResultTab from './ResultTab';

const TabKey = {
  UPLOAD: 'UPLOAD',
  RESULT: 'RESULT',
};

const tabsConfig = [
  {
    eventKey: TabKey.UPLOAD,
    label: 'Kiểm tra trùng lặp',
  },
  {
    eventKey: TabKey.RESULT,
    label: 'Lịch sử kiểm tra',
  },
];

export default function ProjectPlagiarism() {
  const [currentTab, setCurrentTab] = useState(TabKey.UPLOAD);

  return (
    <div className="u-paddingVerticalLarge u-paddingHorizontalSmall">
      <div className="u-backgroundWhite u-border u-roundedLarge u-shadowLarge u-paddingSmall">
        <div className="u-text500 u-fontMedium u-marginBottomSmall">COOPY - Hệ thống Kiểm tra Trùng lặp Nội dung</div>
        <TabGroup
          currentTab={currentTab}
          onSelectTab={setCurrentTab}
          tabsConfig={tabsConfig}
          className="u-marginBottomSmall"
        />
        {currentTab === TabKey.UPLOAD && (
          <UploadTab handleSwitchTab={() => setCurrentTab(TabKey.RESULT)} />
        )}
        {currentTab === TabKey.RESULT && (
          <ResultTab />
        )}
      </div>
    </div>
  );
}
