import React, { useRef, useLayoutEffect, useState } from 'react';
import { SafeAnchor } from '@ahaui/react';
import { v4 as uuidv4 } from 'uuid';

export default function SeeMoreWrapper({
  heightThreshold = 120,
  children,
}) {
  const containerRef = useRef();
  const gradientOverlayRef = useRef();
  const seeMoreRef = useRef();
  const [show, setShow] = useState(false);
  const [isOverThreshold, setIsOverThreshold] = useState(false);
  const [bgColor, setBgColor] = useState('#ffffff');

  const renderNonce = uuidv4();

  useLayoutEffect(() => {
    setTimeout(() => {
      const childHeight = containerRef.current?.firstChild?.clientHeight ?? 0;
      const isOverThreshold = childHeight > heightThreshold;
      setIsOverThreshold(isOverThreshold);
    });
  }, [renderNonce, heightThreshold]);

  useLayoutEffect(() => {
    const containerBgColor = window.getComputedStyle(containerRef.current).backgroundColor;
    if (containerBgColor !== 'rgba(0, 0, 0, 0)') {
      setBgColor(containerBgColor);
    }
  }, [renderNonce]);

  return (
    <div
      className="u-overflowHidden u-positionRelative u-backgroundInherit"
      style={{
        maxHeight: (isOverThreshold && !show) ? heightThreshold : undefined,
      }}
      ref={containerRef}
    >
      {children}
      <div
        ref={gradientOverlayRef}
        style={{
          height: 60,
          display: (isOverThreshold && !show) ? 'block' : 'none',
          backgroundImage: `linear-gradient(to bottom, transparent, ${bgColor} 66%, ${bgColor} 100%)`,
        }}
        className="u-positionAbsolute u-positionBottom u-widthFull"
      />
      <SafeAnchor
        ref={seeMoreRef}
        style={{
          display: isOverThreshold ? 'block' : 'none',
          position: show ? 'static' : 'absolute',
        }}
        className="u-positionBottom u-positionLeft"
        onClick={() => setShow(show => !show)}
      >
        {show ? 'Ẩn bớt' : 'Xem thêm'}
      </SafeAnchor>
    </div>
  );
}
