import React from 'react';
import classNames from 'classnames';

export default function FormFeedback({
  type,
  value,
  className,
  ...formFeedbackProps
}) {
  let additionalClassNames = '';

  if (type === 'info') {
    additionalClassNames += 'u-textInformation';
  }

  return (
    <div
      className={classNames(
        'u-marginTopTiny u-widthFull u-text100',
        additionalClassNames,
        className,
      )}
      {...formFeedbackProps}
    >
      {value}
    </div>
  );
}
