import React, { useEffect, useMemo } from 'react';
import { EmptyState } from '@ahaui/react';
import usePagination from 'hooks/usePagination';
import Pagination from 'components/Common/Table/Pagination';
import EmptyRows from 'components/Common/EmptyRows';

function defaultCellRenderer({
  key,
  rowData,
  columnConfig,
}) {
  return (
    <td key={key}>
      {(columnConfig?.name && rowData?.[columnConfig.name]) ?? '#ERROR (missing cell renderer)'}
    </td>
  );
}

export default function Table({
  columns,
  rows,
  showIndex = true,
  showPagination = true,
  columnsRenderer,
  rowsRenderer,
  emptyRowDescription = 'Không có dữ liệu',
}) {
  const {
    paginatedItems: visibleRows,
    totalItems,
    pageOffset,
    onPageOffsetChange,
    itemsPerPage,
    onItemsPerPageChange,
    firstVisibleRow,
    lastVisibleRow,
  } = usePagination({
    items: rows,
    disabled: !showPagination,
  });

  const columnsConfig = useMemo(() => {
    const cols = [...columns];
    if (showIndex) {
      cols.unshift({
        name: '#',
        cellRenderer: ({ rowIndex, key }) => <td key={key}>{rowIndex + 1}</td>,
        className: 'u-textNoWrap',
        style: { width: '1%' },
      });
    }
    return cols;
  }, [columns, showIndex]);

  const numberOfCols = columnsConfig.length;
  const numberOfRows = 3;

  useEffect(() => {
    onPageOffsetChange(0);
  }, [totalItems, onPageOffsetChange]);

  const renderColumns = () => columnsConfig?.map(({
    header,
    name,
    cellRenderer,
    ...columnProps
  }) => (
    <th
      key={name}
      scope="col"
      {...columnProps}
    >
      {header || name}
    </th>
  ));

  const renderRows = () => {
    if (!visibleRows) {
      return (
        <EmptyRows
          numberOfCols={numberOfCols}
          numberOfRows={numberOfRows}
        />
      );
    }
    if (visibleRows.length === 0) {
      return (
        <tr>
          <td colSpan={numberOfCols} className="u-backgroundWhite">
            <EmptyState name="general" className="u-widthFull">
              <EmptyState.Description>
                {emptyRowDescription}
              </EmptyState.Description>
            </EmptyState>
          </td>
        </tr>
      );
    }
    return visibleRows.map((rowData, rowIndex) => (
      <tr key={rowData.id}>
        {columnsConfig.map((columnConfig, colIndex) => {
          const cellRenderer = columnConfig.cellRenderer || defaultCellRenderer;
          return cellRenderer({
            rowData,
            rowIndex: rowIndex + pageOffset,
            colIndex,
            columnConfig,
            key: `${rowIndex}-${colIndex}`,
          });
        })}
      </tr>
    ));
  };

  return (
    <div>
      {showPagination && (
        <Pagination
          totalItems={totalItems}
          pageOffset={pageOffset}
          itemsPerPage={itemsPerPage}
          firstVisibleRow={firstVisibleRow}
          lastVisibleRow={lastVisibleRow}
          onPageOffsetChange={onPageOffsetChange}
          onItemsPerPageChange={onItemsPerPageChange}
        />
      )}
      <div className="u-marginVerticalExtraSmall u-borderLeft u-borderRight u-borderBottom u-overflowHorizontalAuto">
        <table className="Table Table--striped Table--hoverable u-backgroundWhite u-textDark u-text200">
          <thead className="u-textNoWrap">
            <tr>
              {columnsRenderer && columnsRenderer(columnsConfig)}
              {!columnsRenderer && renderColumns()}
            </tr>
          </thead>
          <tbody>
            {rowsRenderer && rowsRenderer(rows)}
            {!rowsRenderer && renderRows()}
          </tbody>
        </table>
      </div>
      {showPagination && (
        <Pagination
          totalItems={totalItems}
          pageOffset={pageOffset}
          itemsPerPage={itemsPerPage}
          firstVisibleRow={firstVisibleRow}
          lastVisibleRow={lastVisibleRow}
          onPageOffsetChange={onPageOffsetChange}
          onItemsPerPageChange={onItemsPerPageChange}
        />
      )}
    </div>
  );
}
