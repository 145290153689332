import { ModalAction } from 'constants/actions';

const getInitialState = () => ({
  modalKey: null,
  modalProps: null,
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case ModalAction.SHOW_MODAL: {
      return {
        ...action.payload,
      };
    }

    default:
      break;
  }
  return state;
};
