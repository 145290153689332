export const getSuccessAction = (action) => `${action}_SUCCESS`;

export const getFailureAction = (action) => `${action}_FAILURE`;

export const isSuccessAction = (action) => action.endsWith('_SUCCESS');

export const isFailureAction = (action) => action.endsWith('_FAILURE');

export const getOriginalAction = (action) => {
  if (isSuccessAction(action)) {
    return action.slice(0, action.length - 8);
  }
  if (isFailureAction(action)) {
    return action.slice(0, action.length - 8);
  }
  return action;
};
