import { AssetPlugin } from '@ahaui/react';

// Logos
import logoHustPng from '../assets/images/logo/logo-hust.png';

// Empty States
import generalSvg from '../assets/images/emptyState/general.svg';

export const LogoAssetsPlugin = new AssetPlugin({
  prefix: 'logo',
  assets: {
    hustPng: logoHustPng,
  },
});

export const EmptyStateAssetsPlugin = new AssetPlugin({
  prefix: 'emptyState',
  assets: {
    general: generalSvg,
  },
});

export default {
  LogoAssetsPlugin,
  EmptyStateAssetsPlugin,
};
