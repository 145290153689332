import React from 'react';
import DOMPurify from 'dompurify';

export default function AboutSection({
  companyInfo,
}) {
  const {
    description,
  } = companyInfo;

  return (
    <div className="u-paddingTopExtraSmall">
      <div
        className="all-imageMaxWidthFull"
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
      />
    </div>
  );
}
