import { AppAction } from 'constants/actions';

export const blockTask = (taskKey) => ({
  type: AppAction.BLOCK_TASK,
  payload: taskKey,
});

export const unblockTask = (taskKey) => ({
  type: AppAction.UNBLOCK_TASK,
  payload: taskKey,
});

export const cacheRequest = (actionKey) => ({
  type: AppAction.CACHE_REQUEST,
  payload: actionKey,
});

export const cacheRequests = (actionKeys) => ({
  type: AppAction.CACHE_REQUESTS,
  payload: actionKeys,
});

export const clearCachedRequest = (actionKey) => ({
  type: AppAction.clearCachedRequest,
  payload: actionKey,
});
