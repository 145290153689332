import React from 'react';
import classNames from 'classnames';
import { Tab } from '@ahaui/react';

export default function TabGroup({
  currentTab,
  onSelectTab,
  tabsConfig,
  ...tabProps
}) {
  return (
    <Tab
      current={currentTab}
      onSelect={onSelectTab}
      visual="filled"
      {...tabProps}
    >
      {tabsConfig.map(({
        eventKey,
        label,
        className,
        ...tabItemProps
      }, index) => (
        <Tab.Item
          key={eventKey}
          eventKey={eventKey}
          className={classNames(
            'u-borderTop',
            'u-borderLeft',
            index === tabsConfig.length - 1 && 'u-borderRight',
            currentTab !== eventKey && 'u-borderBottom',
            className,
          )}
          {...tabItemProps}
        >
          {label}
        </Tab.Item>
      ))}
      <div className="u-flexGrow1 u-borderBottom" />
    </Tab>
  );
}
