import React from 'react';
import { Modal, Button, Separator } from '@ahaui/react';

function ModalBase(props) {
  const renderFooter = () => {
    const {
      footerType,
      primaryButtonText,
      primaryButtonVariant,
      secondaryButtonText,
      secondaryButtonVariant,
      onClickPrimaryButton,
      onClickSecondaryButton,
      disablePrimaryButton,
      disableSecondaryButton,
      footerInfo,
      footerCheckbox,
    } = props;

    const renderPrimaryButton = (customProps) => (
      <Button
        variant={primaryButtonVariant}
        onClick={onClickPrimaryButton}
        disabled={disablePrimaryButton}
        {...customProps}
      >
        {primaryButtonText}
      </Button>
    );

    const renderSecondaryButton = (customProps) => (
      <Button
        variant={secondaryButtonVariant}
        onClick={onClickSecondaryButton}
        disabled={disableSecondaryButton}
        {...customProps}
      >
        {secondaryButtonText}
      </Button>
    );

    const renderFooterInfo = () => (
      footerInfo && (
      <>
        <Separator variant="lighter" className="u-marginVerticalSmall" />
        {footerInfo}
      </>
      )
    );

    const renderFooterCheckbox = () => (
      footerCheckbox && (
      <div className="u-marginBottomSmall">{footerCheckbox}</div>
      )
    );

    switch (footerType) {
      case 'single':
        return (
          <div className="u-flexGrow-1 u-flex u-flexColumn">
            {renderFooterCheckbox()}
            {renderPrimaryButton({ width: 'full' })}
            {renderFooterInfo()}
          </div>
        );

      case 'double':
        return (
          <div className="u-flexGrow-1 u-flex u-flexColumn">
            {renderFooterCheckbox()}
            {renderSecondaryButton()}
            {renderPrimaryButton()}
            {renderFooterInfo()}
          </div>
        );

      case 'vertical-double':
        return (
          <div className="u-flexGrow-1 u-flex u-flexColumn">
            {renderFooterCheckbox()}
            {renderSecondaryButton({ width: 'full' })}
            {renderPrimaryButton({ width: 'full' })}
            {renderFooterInfo()}
          </div>
        );

      case 'between-double':
        return (
          <div className="u-flexGrow-1 u-flex u-flexColumn">
            {renderFooterCheckbox()}
            <div className="u-flex u-flexGrow-1 u-justifyContentBetween">
              {renderSecondaryButton()}
              {renderPrimaryButton()}
            </div>
            {renderFooterInfo()}
          </div>
        );

      case 'single-right':
        return (
          <div className="u-flexGrow-1 u-flex u-flexColumn">
            {renderFooterCheckbox()}
            <div className="u-flex u-flexGrow-1 u-justifyContentEnd">
              {renderPrimaryButton()}
            </div>
            {renderFooterInfo()}
          </div>
        );
      default:
        return null;
    }
  };

  const {
    header,
    headerText,
    body,
    footer,
    children,
    primaryButtonText,
    onClickPrimaryButton,
    secondaryButtonText,
    onClickSecondaryButton,
    footerType,
    onHide,
    closable,
    disablePrimaryButton,
    disableSecondaryButton,
    showFooter,
    showHeader,
    primaryButtonVariant,
    secondaryButtonVariant,
    footerInfo,
    footerCheckbox,
    ...rest
  } = props;

  return (
    <Modal
      {...rest}
      show
      onHide={onHide}
    >
      {children || (
        <>
          {showHeader && (
            <Modal.Header closeButton={closable}>
              {header || <Modal.Title>{headerText}</Modal.Title>}
            </Modal.Header>
          )}
          <Modal.Body>{body}</Modal.Body>
          {showFooter && (
            <Modal.Footer>{footer || renderFooter()}</Modal.Footer>
          )}
        </>
      )}
    </Modal>
  );
}

ModalBase.defaultProps = {
  footerType: 'single',
  closable: true,
  showFooter: true,
  showHeader: true,
  primaryButtonVariant: 'primary',
  secondaryButtonVariant: 'secondary',
};

export default ModalBase;
