import React, { useEffect, useMemo } from 'react';
import DOMPurify from 'dompurify';
import { useDispatch, useSelector } from 'react-redux';
import { SafeAnchor } from '@ahaui/react';
import { withCache, withBlock } from 'store/actions/hoc.action';
import { getCompanies } from 'store/actions/company.action';
import { getProjectTopicsCompany } from 'store/actions/project.action';
import { showModal } from 'store/actions/modal.action';
import { getProjectTypeName } from 'utils/message';
import useFilter from 'hooks/useFilter';
import { ModalKey } from 'constants/common';
import Table from 'components/Common/Table';
import ToggleShowWrapper from 'components/Common/ToggleShowWrapper';
import Filter from 'components/Common/Filter';

export default function CompanyOrientations() {
  const dispatch = useDispatch();
  const projectTopicsCompany = useSelector(state => state.project.projectTopicsCompany);
  const companies = useSelector(state => state.company.companies);

  const availableProjectTopicsCompany = useMemo(() => {
    if (!projectTopicsCompany || !companies) {
      return null;
    }
    const availableCompanyIds = Object.keys(companies);
    return projectTopicsCompany.filter((topic) => availableCompanyIds.includes(topic.teacherId.toString()));
  }, [projectTopicsCompany, companies]);

  const {
    searchPattern,
    onSearchPatternChange,
    filteredItems: filteredProjectTopicsCompany,
  } = useFilter({
    items: availableProjectTopicsCompany,
    options: {
      keys: ['title', 'description'],
      distance: 500,
    },
  });

  useEffect(() => {
    dispatch(withCache(withBlock(getProjectTopicsCompany())));
    dispatch(withCache(withBlock(getCompanies())));
  }, [dispatch]);

  return (
    <div>
      <Filter.Container>
        <Filter.SearchString
          name="search-by-title"
          value={searchPattern}
          onChange={onSearchPatternChange}
        />
      </Filter.Container>
      <Table
        columns={[
          {
            name: 'Doanh nghiệp',
            cellRenderer: ({
              key,
              rowData,
            }) => {
              const company = companies?.[rowData.teacherId];
              return (
                <td key={key}>
                  <SafeAnchor
                    className="u-fontMedium"
                    onClick={() => dispatch(showModal(ModalKey.COMPANY_INFO, {
                      companyId: company.id,
                    }))}
                  >
                    {company?.name || 'N/A'}
                  </SafeAnchor>
                  {company?.contactUsers?.map((user) => (
                    <div
                      key={user.id}
                      className="u-marginTopExtraSmall"
                    >
                      <div>{user.fullName}</div>
                      <div>{user.phoneNumber}</div>
                      <div>{user.email}</div>
                    </div>
                  ))}
                </td>
              );
            },
          },
          {
            name: 'Hướng đề tài',
            cellRenderer: ({
              key,
              rowData,
            }) => (
              <td key={key}>
                <div className="u-fontMedium u-textPrimary">{rowData.title}</div>
                {rowData.types?.length > 0 && (
                  <div>
                    <span>Loại đề tài: </span>
                    <span className="u-fontMedium">{rowData.types.map(type => getProjectTypeName(type, true)).join(', ')}</span>
                  </div>
                )}
                {/* TODO: EduProgram */}
                <div>
                  <span>Số SV: </span>
                  <span className="u-fontMedium">{rowData.studentNum}</span>
                </div>
                {rowData.description && (
                  <ToggleShowWrapper>
                    <div
                      className="u-paddingVerticalExtraSmall all-imageMaxWidthFull all-text200"
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(rowData.description) }}
                    />
                  </ToggleShowWrapper>
                )}
              </td>
            ),
          },
        ]}
        rows={filteredProjectTopicsCompany}
      />
    </div>
  );
}
