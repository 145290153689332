import React from 'react';
import classNames from 'classnames';

import CenterLoading from './CenterLoading';

export function LoadingOverlay({
  mode = LoadingOverlay.MODE_DARK,
  ...loaderProps
}) {
  return (
    <div
      className={classNames(
        mode === LoadingOverlay.MODE_DARK ? 'u-textLight' : 'u-textDark',
        'u-positionAbsolute u-positionFull',
      )}
      style={{ zIndex: 99999 }}
    >
      <div
        className={classNames(
          mode === LoadingOverlay.MODE_DARK ? 'u-backgroundBlack' : 'u-backgroundWhite',
          'u-positionAbsolute u-positionFull u-opacityThreeQuarter',
        )}
      />
      <CenterLoading
        duration={1500}
        {...loaderProps}
      />
    </div>
  );
}

LoadingOverlay.MODE_DARK = 'MODE_DARK';
LoadingOverlay.MODE_LIGHT = 'MODE_LIGHT';

export default LoadingOverlay;
