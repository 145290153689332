import {
  StatusData,
  CompanyType,
  ProductType,
  CompanyRole,
  UserStatus,
  InternType,
  TopicType,
  TeachingType,
  ProjectType,
  FormStatus,
  FormType,
} from 'constants/common';

export const getProjectRequestStatus = (status) => {
  if (status === StatusData.STATUS_DATA_CONFIRMED) return 'Đã được chấp nhận';
  if (status === StatusData.STATUS_DATA_REJECTED) return 'Đã bị từ chối';
  if (status === StatusData.STATUS_DATA_PROCESSING) return 'Đang xử lý';
  if (status === StatusData.STATUS_DATA_DONE) return 'Đã hoàn thành';
  if (status === StatusData.STATUS_DATA_CANCELLED) return 'Đã huỷ';
  return 'Đang chờ duyệt';
};

export const getCompanyTypeName = (type) => {
  if (type === CompanyType.COMPANY_TYPE_PRIVATE_SHARES) return 'Tư nhân/Cổ phần';
  if (type === CompanyType.COMPANY_TYPE_CORPORATION) return 'Tập đoàn/Tổng công ty';
  if (type === CompanyType.COMPANY_TYPE_FOREIGN_CAPITAL) return 'Vốn nước ngoài';
  if (type === CompanyType.COMPANY_TYPE_STARTUP) return 'Khởi nghiệp';
  if (type === CompanyType.COMPANY_TYPE_STATE_ORGANIZATION) return 'Tổ chức nhà nước';
  return 'N/A';
};

export const getProductTypeName = (type) => {
  if (type === ProductType.PRODUCT_TYPE_OUT_SOURSE) return 'Gia công phần mềm (Outsource, onsite)';
  if (type === ProductType.PRODUCT_TYPE_RESEARCH) return 'Nghiên cứu và phát triển (R&D)';
  if (type === ProductType.PRODUCT_TYPE_GAMES) return 'Games';
  if (type === ProductType.PRODUCT_TYPE_PRODUCT_SERVICE) return 'Phát triển sản phẩm/dịch vụ';
  if (type === ProductType.PRODUCT_TYPE_STARTUP) return 'Dự án mới, khởi nghiệp (Startup)';
  if (type === ProductType.PRODUCT_TYPE_ECOMMERCE) return 'Thương mại điện tử (eCommerce)';
  if (type === ProductType.PRODUCT_TYPE_FINTECH) return 'Tài chính Ngân hàng (FinTech)';
  if (type === ProductType.PRODUCT_TYPE_EGOV) return 'Điện tử chính phủ';
  if (type === ProductType.PRODUCT_TYPE_EHEALTH) return 'Y tế sức khoẻ';
  if (type === ProductType.PRODUCT_TYPE_EDUCATION) return 'Giáo dục (EdTech)';
  return 'N/A';
};

export const getCompanyRoleName = (role) => {
  if (role === CompanyRole.ROLE_COMPANY_STAFF) return 'Nhân viên';
  if (role === CompanyRole.ROLE_COMPANY_CONTACT) return 'Người liên lạc';
  if (role === CompanyRole.ROLE_COMPANY_MANAGER) return 'Quản lý';
  return 'N/A';
};

export const getStaffStatusName = (status) => {
  if (status === UserStatus.USER_STATUS_NORMAL) return 'Bình thường';
  if (status === UserStatus.USER_STATUS_QUIT) return 'Đã nghỉ';
  if (status === UserStatus.USER_STATUS_NEW) return 'Chưa kích hoạt';
  return 'N/A';
};

export const getInternTypeName = (type) => {
  if (type === InternType.INTERN_FULLTIME) return 'Full-time';
  if (type === InternType.INTERN_PARTTIME) return 'Part-time';
  return 'N/A';
};

export const getTopicTypeName = (type) => {
  if (type === TopicType.TOPIC_TYPE_NEW_TECHNO) return 'Tìm hiểu Công nghệ mới';
  if (type === TopicType.TOPIC_TYPE_DEMO_PRODUCT) return 'Làm sản phẩm Demo';
  if (type === TopicType.TOPIC_TYPE_REAL_PROJECT) return 'Tham gia dự án thực tế';
  if (type === TopicType.TOPIC_TYPE_TRAINING) return 'Tham gia khoá đào tạo';
  if (type === TopicType.TOPIC_TYPE_OTHERS) return 'Loại hình khác';
  return 'N/A';
};

export const getInternReadyMessage = (value) => {
  if (value === 1) return 'Nhận Sinh viên thực tập';
  if (value === 0) return 'Không nhận Sinh viên thực tập';
  return 'N/A';
};

export const getTeachingTypeName = (teachingType) => {
  if (teachingType === TeachingType.TYPE_TEACHING_OFFLINE) return 'Offline';
  if (teachingType === TeachingType.TYPE_TEACHING_ONLINE) return 'Online';
  if (teachingType === TeachingType.TYPE_TEACHING_BLEARNING) return 'BLearning';
  return 'N/A';
};

export const getProjectTypeName = (type, isShortName) => {
  if (type === ProjectType.PROJECT_TYPE_GRADUATION) return isShortName ? 'ĐATN' : 'Đồ án tốt nghiệp';
  if (type === ProjectType.PROJECT_TYPE_GRADUATION_INTERN) return isShortName ? 'TTTN' : 'Thực tập Tốt nghiệp';
  if (type === ProjectType.PROJECT_TYPE_INTERN) return isShortName ? 'TT' : 'Thực Tập';
  if (type === ProjectType.PROJECT_TYPE_PROFESSIONAL_MASTER) return isShortName ? 'ThSKT' : 'Thạc sỹ Kỹ thuật';
  if (type === ProjectType.PROJECT_TYPE_RESEARCH_MASTER) return isShortName ? 'ThSKH' : 'Thạc sỹ Khoa học';
  if (type === ProjectType.PROJECT_TYPE_PHD) return isShortName ? 'LATS' : 'Luận án Tiến sỹ';
  if (type === ProjectType.PROJECT_TYPE_PROJECT) return isShortName ? 'ĐAMH' : 'Đồ án môn học';
  return 'N/A';
};

export const getFormStatusName = (status) => {
  if (status === FormStatus.FORM_STATUS_CANCELLED) return 'Sinh viên Hủy';
  if (status === FormStatus.FORM_STATUS_FINISH) return 'Đã xong';
  if (status === FormStatus.FORM_STATUS_PROCESSING) return 'Đang xử lý';
  if (status === FormStatus.FORM_STATUS_REJECT) return 'Bị từ chối';
  if (status === FormStatus.FORM_STATUS_WATTING) return 'Đang chờ';
  return 'N/A';
};

export const getFormName = (formType) => {
  switch (formType) {
    case FormType.FORM_TYPE_BDSV: return 'Bảng điểm';
    case FormType.FORM_TYPE_CNSV: return 'Chứng nhận SV (Cấp Viện)';
    case FormType.FORM_TYPE_CNSVCT: return 'Chứng nhận SV (Cấp Trường)';
    case FormType.FORM_TYPE_CNTNSV: return 'Chứng nhận Tốt nghiệp';
    case FormType.FORM_TYPE_DTSV: return 'Thẻ Sinh Viên';
    case FormType.FORM_TYPE_GGT: return 'Giấy Giới thiệu (Cấp Viện)';
    case FormType.FORM_TYPE_GGTCT: return 'Giấy Giới thiệu (Cấp Trường)';
    case FormType.FORM_TYPE_GXN: return 'Giấy Xác nhận';
    case FormType.FORM_TYPE_MDC: return 'Mẫu đơn chung';
    case FormType.FORM_TYPE_CDTD: return 'Chuyển điểm tương đương';
    case FormType.FORM_TYPE_BMTSV: return 'Báo mất thẻ Sinh viên';
    case FormType.FORM_TYPE_RHS: return 'Rút hồ sơ';
    case FormType.FORM_TYPE_TTDN: return 'Đánh giá kết quả thực tập doanh nghiệp';
    case FormType.FORM_TYPE_HTCTDT: return 'Chứng nhận hoàn thành Chương trình đào tạo';
    case FormType.FORM_TYPE_DKHT: return 'Đơn xin đăng ký học';
    case FormType.FORM_TYPE_GUQ: return 'Giấy uỷ quyền nhận Bằng/Hồ sơ Tốt nghiệp';
    default: return 'N/A';
  }
};
